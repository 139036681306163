import React from 'react';
import styled from 'styled-components';
import theme from '../theme';

const FooterContainer = styled.footer`
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  padding: 1.5rem 2rem;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid ${theme.colors.border};
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

const FooterText = styled.div`
  color: ${theme.colors.textSecondary};
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
`;

const EmailLink = styled.a`
  color: ${theme.colors.primary};
  text-decoration: none;
  transition: color 0.2s ease;
  
  &:hover {
    color: ${theme.colors.secondary};
    text-decoration: underline;
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <FooterContainer>
      <FooterContent>
        <FooterText>
          AI-powered location intelligence from Charleston, SC · <EmailLink href="mailto:info@moveflow.ai">info@moveflow.ai</EmailLink> · © {currentYear} Moveflow Technologies
        </FooterText>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;