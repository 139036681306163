import axios from 'axios';

// Use the full API URL from environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://api.localhost';
console.log('API URL:', API_URL);

const apiKeyService = {
  getApiKeys: async (token) => {
    console.log('Getting API keys with token:', token ? token.substring(0, 10) + '...' : 'No token');
    try {
      const response = await axios.get(`${API_URL}/api-keys`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('API keys response:', response);
      return response;
    } catch (error) {
      console.error('Error getting API keys:', error.message, error.response?.status, error.response?.data);
      throw error;
    }
  },
  
  createApiKey: async (name, permissions, expirationDays, token) => {
    try {
      // Ensure permissions is an array of strings
      const permissionsArray = Array.isArray(permissions) ? permissions : [permissions];
      
      // Format the request body according to what the API expects
      const requestBody = {
        name: name,
        permissions: permissionsArray,  // This should be a simple array like ['read'] or ['read', 'write']
      };
      
      // Only include expiration_days if it's not null (for "Never" option)
      if (expirationDays !== null) {
        requestBody.expiration_days = expirationDays;
      }
      
      console.log('Creating API key with request body:', JSON.stringify(requestBody));
      const response = await axios.post(`${API_URL}/api-keys/`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Create API key response:', response);
      return response;
    } catch (error) {
      console.error('Error creating API key:', error.message);
      if (error.response) {
        console.error('Response status:', error.response.status);
        console.error('Response data:', error.response.data);
      }
      throw error;
    }
  },
  
  revokeApiKey: async (keyId, token) => {
    try {
      console.log('Revoking API key:', keyId);
      const response = await axios.delete(`${API_URL}/api-keys/${keyId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Revoke API key response:', response);
      return response;
    } catch (error) {
      console.error('Error revoking API key:', error.message, error.response?.status, error.response?.data);
      throw error;
    }
  }
};

export default apiKeyService; 