import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { AuthProvider } from '@propelauth/react';

// Pages
import Home from './pages/Home';
import Products from './pages/Technology';
import Demo from './pages/Demo';
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';
import Developer from './pages/Developer';

// Components
import { LoadingComponent } from './components';

// Styles and Theme
import './App.css';
import theme from './theme';

// Utils
import { logAnalyticsEvent } from './utils/firebase';

const AppWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.background};
`;

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Log app initialization event
    logAnalyticsEvent('app_initialized');
    
    // Set a flag in sessionStorage to indicate this is the initial app load
    sessionStorage.setItem('initialAppLoad', 'true');
    
    // Simulate loading time - increased to 2000ms to ensure Home component is ready
    const timer = setTimeout(() => {
      setLoading(false);
      // After a short delay, remove the initial load flag
      setTimeout(() => {
        sessionStorage.removeItem('initialAppLoad');
      }, 1000);
    }, 2000);
    
    return () => clearTimeout(timer);
  }, []);

  return (
    <AuthProvider authUrl={process.env.REACT_APP_PROPELAUTH_AUTH_URL}>
      <ThemeProvider theme={theme}>
        {loading ? (
          <LoadingComponent 
            fullScreen 
            size="50px"
            borderWidth="5px"
            color={theme.colors.primary}
          />
        ) : (
          <AppWrapper>
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/map" element={<Demo />} />
                <Route path="/offerings" element={<Products />} />
                <Route path="/developers" element={<Developer />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Router>
          </AppWrapper>
        )}
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App; 