import React from 'react';
import { useRedirectFunctions, useAuthInfo, useLogoutFunction } from '@propelauth/react';
import styled from 'styled-components';
import theme from '../theme';
import colors from '../theme/colors';

const StyledButton = styled.button`
  background-color: ${props => props.isLoggedIn ? 'transparent' : colors.primary};
  color: ${props => props.isLoggedIn ? colors.primary : 'white'};
  border: ${props => props.isLoggedIn ? `1px solid ${colors.primary}` : 'none'};
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${props => props.isLoggedIn ? colors.primary : (colors.muted)};
    color: white;
    opacity: 0.9;
  }
`;

function LoginButton() {
  const { redirectToLoginPage } = useRedirectFunctions();
  const { isLoggedIn } = useAuthInfo();
  const logout = useLogoutFunction();

  if (isLoggedIn) {
    return (
      <StyledButton isLoggedIn={true} onClick={() => logout(true)}>
        Logout
      </StyledButton>
    );
  }

  return (
    <StyledButton isLoggedIn={false} onClick={() => redirectToLoginPage()}>
      Login
    </StyledButton>
  );
}

export default LoginButton; 