import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../images/logo_moveflow_04_cleaned.svg';
import theme from '../theme';
import LoginButton from './LoginButton';

const HeaderContainer = styled.header`
  background-color: ${theme.colors.background};
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  
  @media (min-width: 768px) {
    padding: 1.5rem 4rem;
  }
`;

const Logo = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${theme.colors.primary};
  display: flex;
  align-items: center;
  
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const LogoImage = styled.img`
  height: 40px;
  margin-right: 8px;
  
  @media (min-width: 768px) {
    height: 50px;
    margin-right: 10px;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

const DesktopNav = styled.div`
  display: none;
  
  @media (min-width: 768px) {
    display: flex;
    gap: 2.5rem;
  }
`;

const MobileNav = styled.div`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  background-color: ${theme.colors.background};
  padding: 1.5rem;
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  gap: 1.5rem;
  
  @media (min-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  color: ${theme.colors.text};
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
  
  &:hover {
    color: ${theme.colors.primary};
    text-decoration: none;
  }
  
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: ${theme.colors.primary};
    transition: width 0.3s ease;
  }
  
  &:hover:after {
    width: 100%;
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${theme.colors.text};
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    display: flex;
  }
`;

const LoginButtonWrapper = styled.div`
  margin-left: 2rem;
`;

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  return (
    <>
      <HeaderContainer>
        <Logo>
          <Link to="/" style={{ color: theme.colors.primary, textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            <LogoImage src={logo} alt="Moveflow Logo" />
          </Link>
        </Logo>
        
        <DesktopNav>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/map">Map</NavLink>
          <NavLink to="/offerings">Offerings</NavLink>
          <NavLink to="/developers">Developers</NavLink>
          <NavLink to="/contact">Contact</NavLink>
          <LoginButtonWrapper>
            <LoginButton />
          </LoginButtonWrapper>
        </DesktopNav>
        
        <MobileMenuButton onClick={toggleMenu} aria-label="Toggle menu">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            <path d="M3 12H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            <path d="M3 18H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </MobileMenuButton>
      </HeaderContainer>
      
      <MobileNav isOpen={isMenuOpen}>
        <NavLink to="/" onClick={toggleMenu}>Home</NavLink>
        <NavLink to="/map" onClick={toggleMenu}>Map</NavLink>
        <NavLink to="/offerings" onClick={toggleMenu}>Offerings</NavLink>
        <NavLink to="/developers" onClick={toggleMenu}>Developers</NavLink>
        <NavLink to="/contact" onClick={toggleMenu}>Contact</NavLink>
        <LoginButton />
      </MobileNav>
    </>
  );
};

export default Header; 