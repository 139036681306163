import React, { useState, useEffect } from 'react';
import { useAuthInfo } from '@propelauth/react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import apiKeyService from '../services/apiKeyService';
import theme from '../theme';
import colors from '../theme/colors';
import SEO from '../components/SEO';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark, tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Define API URL for use in code examples
const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://api.localhost';

// Main container styles
const DeveloperPageWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: ${colors.background};
`;

const DeveloperContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const DeveloperHeader = styled.div`
  margin-bottom: 2rem;
`;

const DeveloperTitle = styled.h1`
  font-size: 2rem;
  color: ${theme.colors.text};
  margin-bottom: 0.5rem;
`;

const DeveloperSubtitle = styled.p`
  color: ${theme.colors.textLight || '#666'};
`;

const Section = styled.div`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${theme.colors.text};
`;

const KeysTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
`;

const TableHead = styled.thead`
  background-color: ${theme.colors.backgroundLight || '#f5f5f5'};
`;

const TableRow = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.backgroundLight || '#f5f5f5'};
  }
`;

const TableHeaderCell = styled.th`
  text-align: left;
  padding: 1rem;
  font-weight: 600;
`;

const TableCell = styled.td`
  padding: 1rem;
`;

const Button = styled.button`
  background-color: ${props => props.danger ? (theme.colors.danger || '#dc3545') : theme.colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  margin-right: 0.5rem;

  &:hover {
    opacity: 0.9;
  }
`;

const Form = styled.form`
  background-color: ${theme.colors.backgroundLight || '#f5f5f5'};
  padding: 1.5rem;
  border-radius: 8px;
  max-width: 600px;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

// Contact form styles
const ContactSection = styled.section`
  margin-bottom: 5rem;
  width: 100%;
  box-sizing: border-box;
`;

const ContactText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  color: ${colors.text};
  
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

const FormContainer = styled.div`
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  max-width: 600px;
  margin: 0 auto;
`;

const FormTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.text};
  margin-bottom: 1.5rem;
  font-family: ${theme.typography.fontFamily.secondary};
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: ${colors.text};
  font-weight: 500;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${colors.border || colors.muted + '33'};
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px ${colors.primary}33;
  }
`;

const TextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid ${colors.border || colors.muted + '33'};
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  min-height: 150px;
  box-sizing: border-box;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px ${colors.primary}33;
  }
`;

const SubmitButton = styled.button`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  align-self: flex-start;
  
  &:hover {
    background-color: ${colors.primaryDark || colors.primary};
    opacity: 0.9;
  }
  
  &:disabled {
    background-color: ${colors.muted}66;
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled.div`
  background-color: ${colors.success}15;
  color: ${colors.success};
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  border: 1px solid ${colors.success}30;
`;

const ErrorMessage = styled.div`
  background-color: ${colors.active}22;
  color: ${colors.active};
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const KeyDisplay = styled.pre`
  background-color: ${colors.background};
  padding: 1rem;
  border-radius: 4px;
  margin: 1rem 0;
  overflow-x: auto;
  border: 1px solid ${colors.success}30;
`;

const CopyButton = styled.button`
  background-color: ${colors.success};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 0.5rem;
  
  &:hover {
    background-color: ${colors.success}dd;
    opacity: 0.9;
  }
`;

// Add these new styled components for the documentation section
const DocsSection = styled.div`
  margin-bottom: 2rem;
`;

const EndpointTitle = styled.h3`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: ${theme.colors.text};
`;

const EndpointDescription = styled.p`
  margin-bottom: 1rem;
  line-height: 1.5;
`;

const LanguageTabs = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid ${colors.border || colors.muted + '33'};
`;

const LanguageTab = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${props => props.active ? colors.backgroundLight : 'transparent'};
  border: none;
  border-bottom: 2px solid ${props => props.active ? colors.primary : 'transparent'};
  cursor: pointer;
  font-weight: ${props => props.active ? '600' : '400'};
  color: ${props => props.active ? colors.primary : colors.text};
  
  &:hover {
    background-color: ${colors.backgroundLight}80;
  }
`;

// Replace the CodeBlock styled component with this wrapper
const CodeBlockWrapper = styled.div`
  margin-bottom: 1.5rem;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
  & pre {
    margin: 0 !important;
    border-radius: 0 !important;
  }
`;

const ParameterTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
`;

const ParameterRow = styled.tr`
  border-bottom: 1px solid ${colors.border || colors.muted + '33'};
  
  &:last-child {
    border-bottom: none;
  }
`;

const ParameterCell = styled.td`
  padding: 0.75rem 0.5rem;
  vertical-align: top;
  
  &:first-child {
    font-weight: 600;
    width: 20%;
  }
  
  &:nth-child(2) {
    width: 15%;
    color: ${colors.textLight || '#666'};
  }
`;

// Component for non-logged in users
const DeveloperContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError('');
    
    try {
      // Simulate form submission
      await new Promise(resolve => setTimeout(resolve, 1000));
      setSubmitSuccess(true);
      setFormData({
        name: '',
        email: '',
        company: '',
        message: ''
      });
    } catch (error) {
      setSubmitError('There was an error submitting your request. Please try again.');
      console.error('Form submission error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ContactSection>
      <DeveloperTitle>Developer Access</DeveloperTitle>
      <ContactText>
        Our API provides access to powerful demographic data and location intelligence. 
        If you're interested in developer access, please fill out the form below and our team will get back to you.
      </ContactText>
      
      <FormContainer>
        <FormTitle>Request Developer Access</FormTitle>
        
        {submitSuccess && (
          <SuccessMessage>
            Thank you for your interest! We've received your request and will contact you soon.
          </SuccessMessage>
        )}
        
        {submitError && (
          <ErrorMessage>{submitError}</ErrorMessage>
        )}
        
        <ContactForm onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="company">Company</Label>
            <Input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
            />
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="message">How do you plan to use our API?</Label>
            <TextArea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </FormGroup>
          
          <SubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit Request'}
          </SubmitButton>
        </ContactForm>
      </FormContainer>
    </ContactSection>
  );
};

// Main Developer component
const Developer = () => {
  const { isLoggedIn, accessToken, user } = useAuthInfo();
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    permissions: ['read'],
    expirationDays: 30
  });
  const [createSuccess, setCreateSuccess] = useState(false);
  const [newKey, setNewKey] = useState(null);
  const [revokeSuccess, setRevokeSuccess] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      loadApiKeys();
    } else {
      setLoading(false);
    }
  }, [isLoggedIn, accessToken]);

  const loadApiKeys = async () => {
    try {
      setLoading(true);
      const response = await apiKeyService.getApiKeys(accessToken);
      setApiKeys(response.data);
      setError('');
    } catch (err) {
      console.error('Error loading API keys:', err);
      setError('Failed to load API keys. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateKey = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await apiKeyService.createApiKey(
        formData.name,
        formData.permissions,
        formData.expirationDays,
        accessToken
      );
      setFormData({
        name: '',
        permissions: ['read'],
        expirationDays: 30
      });
      setCreateSuccess(true);
      setNewKey(response.data);
      loadApiKeys();
    } catch (err) {
      console.error('Error creating API key:', err);
      setError('Failed to create API key. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleRevokeKey = async (keyId) => {
    // Add confirmation dialog
    if (!window.confirm("Are you sure you want to revoke this API key? This action cannot be undone.")) {
      return;
    }
    
    try {
      // Show loading state
      setLoading(true);
      
      // Make a direct API call
      await apiKeyService.revokeApiKey(keyId, accessToken);
      
      // Show success message
      setRevokeSuccess(true);
      
      // Force page reload after a delay
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      
    } catch (err) {
      console.error('Error revoking API key:', err);
      // Force page reload even on error
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePermissionChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prev => {
      if (checked) {
        return {
          ...prev,
          permissions: [...prev.permissions, value]
        };
      } else {
        return {
          ...prev,
          permissions: prev.permissions.filter(p => p !== value)
        };
      }
    });
  };

  // Code example component with language switcher
  const CodeExamples = ({ endpoint, method, description, params, responseExample }) => {
    const [activeLanguage, setActiveLanguage] = useState('curl');
    
    // Format the request body for examples
    const requestBody = JSON.stringify({
      latitude: 29.4252,
      longitude: -98.4946,
      radius_miles: 0.5
    }, null, 2);
    
    // Generate code examples for each language
    const examples = {
      curl: `curl -X POST "${API_URL}${endpoint}" \\
-H "X-API-Key: YOUR_API_KEY" \\
-H "Content-Type: application/json" \\
-d '${JSON.stringify({
    latitude: 29.4252,
    longitude: -98.4946,
    radius_miles: 0.5
  })}'`,
      
      python: `import requests

url = "${API_URL}${endpoint}"
headers = {
    "X-API-Key": "YOUR_API_KEY",
    "Content-Type": "application/json"
}
payload = ${requestBody}

response = requests.post(url, json=payload, headers=headers)
data = response.json()
print(data)`,
      
      javascript: `fetch('${API_URL}${endpoint}', {
  method: 'POST',
  headers: {
    'X-API-Key': 'YOUR_API_KEY',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(${requestBody})
})
.then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error('Error:', error));`
    };
    
    // Map language names to syntax highlighter language identifiers
    const languageMap = {
      curl: 'bash',
      python: 'python',
      javascript: 'javascript'
    };
    
    return (
      <DocsSection>
        <EndpointTitle>{method} {endpoint}</EndpointTitle>
        <EndpointDescription>{description}</EndpointDescription>
        
        <h4 style={{ marginBottom: '0.75rem' }}>Parameters</h4>
        <ParameterTable>
          <thead>
            <ParameterRow>
              <ParameterCell>Parameter</ParameterCell>
              <ParameterCell>Type</ParameterCell>
              <ParameterCell>Description</ParameterCell>
            </ParameterRow>
          </thead>
          <tbody>
            {params.map((param, index) => (
              <ParameterRow key={index}>
                <ParameterCell>{param.name}</ParameterCell>
                <ParameterCell>{param.type}</ParameterCell>
                <ParameterCell>{param.description}</ParameterCell>
              </ParameterRow>
            ))}
          </tbody>
        </ParameterTable>
        
        <h4 style={{ marginBottom: '0.75rem' }}>Request Example</h4>
        <LanguageTabs>
          <LanguageTab 
            active={activeLanguage === 'curl'} 
            onClick={() => setActiveLanguage('curl')}
          >
            cURL
          </LanguageTab>
          <LanguageTab 
            active={activeLanguage === 'python'} 
            onClick={() => setActiveLanguage('python')}
          >
            Python
          </LanguageTab>
          <LanguageTab 
            active={activeLanguage === 'javascript'} 
            onClick={() => setActiveLanguage('javascript')}
          >
            JavaScript
          </LanguageTab>
        </LanguageTabs>
        
        <CodeBlockWrapper>
          <SyntaxHighlighter 
            language={languageMap[activeLanguage]} 
            style={atomDark}
            customStyle={{ 
              padding: '1.5rem',
              fontSize: '0.9rem',
              lineHeight: '1.5'
            }}
            showLineNumbers={true}
          >
            {examples[activeLanguage]}
          </SyntaxHighlighter>
        </CodeBlockWrapper>
        
        {responseExample && (
          <>
            <h4 style={{ marginBottom: '0.75rem', marginTop: '1.5rem' }}>Response Example</h4>
            <CodeBlockWrapper>
              <SyntaxHighlighter 
                language="json" 
                style={atomDark}
                customStyle={{ 
                  padding: '1rem',
                  fontSize: '0.9rem'
                }}
                showLineNumbers={true}
              >
                {responseExample}
              </SyntaxHighlighter>
            </CodeBlockWrapper>
          </>
        )}
      </DocsSection>
    );
  };

  return (
    <Layout>
      <SEO 
        title="Developer API Access | Moveflow"
        description="Access Moveflow's powerful demographic data API for your applications. Get API keys and documentation for seamless integration."
        keywords="API, developer, demographic data, location intelligence, API keys, integration"
        path="/developers"
      />
      <DeveloperPageWrapper>
        <DeveloperContainer>
          {isLoggedIn ? (
            <>
              <DeveloperHeader>
                <DeveloperTitle>Developer Portal</DeveloperTitle>
                <DeveloperSubtitle>Manage your API keys and access our documentation</DeveloperSubtitle>
              </DeveloperHeader>
              
              {error && <ErrorMessage>{error}</ErrorMessage>}
              {revokeSuccess && (
                <SuccessMessage>
                  API key revoked successfully!
                </SuccessMessage>
              )}
              
              <Section>
                <SectionTitle>Your API Keys</SectionTitle>
                {loading ? (
                  <p>Loading your API keys...</p>
                ) : (
                  <>
                    {apiKeys.length === 0 ? (
                      <p>You don't have any API keys yet. Create one below to get started.</p>
                    ) : (
                      <KeysTable>
                        <TableHead>
                          <TableRow>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Key</TableHeaderCell>
                            <TableHeaderCell>Permissions</TableHeaderCell>
                            <TableHeaderCell>Created</TableHeaderCell>
                            <TableHeaderCell>Expires</TableHeaderCell>
                            <TableHeaderCell>Actions</TableHeaderCell>
                          </TableRow>
                        </TableHead>
                        <tbody>
                          {apiKeys.map(key => (
                            <TableRow key={key.id}>
                              <TableCell>{key.name}</TableCell>
                              <TableCell>{key.key}</TableCell>
                              <TableCell>{key.permissions.join(', ')}</TableCell>
                              <TableCell>{new Date(key.created_at).toLocaleDateString()}</TableCell>
                              <TableCell>
                                {key.expires_at 
                                  ? new Date(key.expires_at).toLocaleDateString() 
                                  : 'Never'}
                              </TableCell>
                              <TableCell>
                                <Button 
                                  danger 
                                  onClick={() => handleRevokeKey(key.id)}
                                >
                                  Revoke
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </tbody>
                      </KeysTable>
                    )}
                  </>
                )}
              </Section>
              
              <Section>
                <SectionTitle>Create New API Key</SectionTitle>
                {createSuccess && !newKey && (
                  <SuccessMessage>
                    API key created successfully!
                  </SuccessMessage>
                )}
                
                {newKey && (
                  <SuccessMessage>
                    <p>API key created successfully! Please copy your API key now, you won't be able to see it again.</p>
                    <KeyDisplay>{newKey.key}</KeyDisplay>
                    <CopyButton onClick={() => {
                      navigator.clipboard.writeText(newKey.key);
                      alert('API key copied to clipboard!');
                    }}>
                      Copy to Clipboard
                    </CopyButton>
                  </SuccessMessage>
                )}
                
                {error && <ErrorMessage>{error}</ErrorMessage>}
                
                <Form onSubmit={handleCreateKey}>
                  <FormGroup>
                    <Label htmlFor="name">Key Name</Label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="e.g. Development Key"
                      required
                    />
                  </FormGroup>
                  
                  <FormGroup>
                    <Label>Permissions</Label>
                    <div>
                      <label style={{ marginRight: '1rem' }}>
                        <input
                          type="checkbox"
                          value="read"
                          checked={formData.permissions.includes('read')}
                          onChange={handlePermissionChange}
                        /> Read
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="write"
                          checked={formData.permissions.includes('write')}
                          onChange={handlePermissionChange}
                        /> Write
                      </label>
                    </div>
                  </FormGroup>
                  
                  <FormGroup>
                    <Label htmlFor="expirationDays">Expiration</Label>
                    <select
                      id="expirationDays"
                      name="expirationDays"
                      value={formData.expirationDays}
                      onChange={handleInputChange}
                      style={{ padding: '0.5rem', borderRadius: '4px', width: '100%' }}
                    >
                      <option value={30}>30 days</option>
                      <option value={90}>90 days</option>
                      <option value={365}>1 year</option>
                      <option value={null}>Never</option>
                    </select>
                  </FormGroup>
                  
                  <Button type="submit" disabled={loading}>
                    {loading ? 'Creating...' : 'Create API Key'}
                  </Button>
                </Form>
              </Section>
              
              <Section>
                <SectionTitle>API Documentation</SectionTitle>
                <p>Use the following base URL for all API requests:</p>
                <CodeBlockWrapper>
                  <SyntaxHighlighter 
                    language="bash" 
                    style={atomDark}
                    customStyle={{ 
                      padding: '1rem',
                      fontSize: '0.9rem'
                    }}
                  >
                    {API_URL}
                  </SyntaxHighlighter>
                </CodeBlockWrapper>
                
                <h3 style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>Authentication</h3>
                <p>Include your API key in the X-API-Key header for all requests:</p>
                <CodeBlockWrapper>
                  <SyntaxHighlighter 
                    language="bash" 
                    style={atomDark}
                    customStyle={{ 
                      padding: '1rem',
                      fontSize: '0.9rem'
                    }}
                  >
                    X-API-Key: YOUR_API_KEY
                  </SyntaxHighlighter>
                </CodeBlockWrapper>
                
                <h3 style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>Endpoints</h3>
                
                <CodeExamples 
                  endpoint="/neighborhood/insights"
                  method="POST"
                  description="Get detailed demographic insights for a specific location. This endpoint returns comprehensive data about the population, households, education levels, transportation, and more."
                  params={[
                    { name: "latitude", type: "number", description: "Latitude coordinate of the location" },
                    { name: "longitude", type: "number", description: "Longitude coordinate of the location" },
                    { name: "radius_miles", type: "number", description: "Radius in miles around the coordinates to analyze (default: 0.5)" }
                  ]}
                  responseExample={`{
  "age": {
    "mean": 45.2,
    "median": 48.0,
    "under_5_percentage": 3.97,
    "age_5_to_13_percentage": 9.81,
    "age_14_to_17_percentage": 4.67,
    "children_percentage": 18.46
  },
  "household": {
    "average_size": 2.0,
    "average_income": 185182.72,
    "median_income": 155400.0,
    "percentage_with_children": 22.07
  },
  "education": {
    "levels": {
      "bachelor": 34.86,
      "no high school": 18.03,
      "master": 17.55,
      "some college": 8.41,
      "associate's degree": 8.17,
      "high school": 5.77,
      "professional degree": 5.53,
      "Ph.D.": 0.96,
      "GED": 0.72
    },
    "college_educated_percentage": 57.93,
    "high_school_percentage": 6.49,
    "less_than_high_school": 18.03,
    "school_attendance": {
      "attendance_rate": 120.9,
      "distribution": {
        "grade 2": 12.35,
        "grade 7": 11.11,
        "grade 12": 9.88,
        "grade 10": 9.88,
        "grade 1": 8.64,
        "college": 8.64,
        "grade 5": 7.41,
        "grade 6": 6.17,
        "grade 11": 6.17,
        "grade 3": 6.17,
        "preschool": 4.94,
        "grade 8": 3.7,
        "grade 4": 2.47,
        "kindergarten": 2.47
      }
    }
  },
  "race": {
    "distribution": {
      "White": 94.39,
      "two or more races": 4.21,
      "Asian": 0.93,
      "Black": 0.47
    },
    "by_age_group": {
      "Kids": {
        "Asian": 0.0,
        "Black": 0.0,
        "White": 94.94,
        "two or more races": 5.06
      },
      "Adults": {
        "Asian": 0.8,
        "Black": 0.8,
        "White": 93.17,
        "two or more races": 5.22
      },
      "Seniors": {
        "Asian": 2.0,
        "Black": 0.0,
        "White": 97.0,
        "two or more races": 1.0
      }
    },
    "diversity_index_by_age": {
      "Kids": 0.096,
      "Adults": 0.129,
      "Seniors": 0.059
    }
  },
  "diversity_index": 0.107,
  "transportation": {
    "avg_cars_per_household": 2.0,
    "no_car_percentage": 1.64,
    "one_car_percentage": 24.53,
    "multiple_cars_percentage": 73.83,
    "commute_modes": {
      "car": 71.95,
      "home": 27.15,
      "other": 0.9
    },
    "commute_time": {
      "average_minutes": 24.5,
      "median_minutes": 20.0,
      "under_15min_percentage": 20.5,
      "15_to_30min_percentage": 65.22,
      "30_to_60min_percentage": 11.8,
      "over_60min_percentage": 2.48
    }
  },
  "work": {
    "avg_weekly_working_hours": 39.3
  },
  "language": {
    "distribution": {
      "English": 95.09,
      "other European language": 3.27,
      "other": 1.17,
      "Spanish": 0.47
    }
  },
  "location": {
    "latitude": 29.4252,
    "longitude": -98.4946,
    "radius_miles": 0.5
  },
  "moveflow_score": {
    "score": 78,
    "rating": "Very Good",
    "components": {
      "economic": 22.5,
      "education": 22.7,
      "lifestyle": 15.3,
      "mobility": 18.4
    }
  }
}`}
                />
                
                <CodeExamples 
                  endpoint="/neighborhood/summary"
                  method="POST"
                  description="Get a natural language summary of a neighborhood. This endpoint provides a human-readable description of the area's characteristics, including demographic profile, family friendliness, and transportation information."
                  params={[
                    { name: "latitude", type: "number", description: "Latitude coordinate of the location" },
                    { name: "longitude", type: "number", description: "Longitude coordinate of the location" },
                    { name: "radius_miles", type: "number", description: "Radius in miles around the coordinates to analyze (default: 0.5)" }
                  ]}
                  responseExample={`{
  "demographic_profile": "A high-income area with highly educated residents. The median household income is $155,400, with 58% having a college degree.",
  "family_friendliness": "Family-friendly with a moderate number of children. Most common school grades: grade 2 (12%), grade 7 (11%), grade 12 (10%). 4.0% are under 5, 9.8% are 5-13, and 4.7% are teenagers.",
  "transportation": "Primarily car commuters with quick commutes (avg. 24.5 min). 2.0 cars per household, with 1.6% of households having no car.",
  "diversity": "Limited diversity neighborhood with main demographics being White (94%) and two or more races (4%). Primarily English-speaking.",
  "summary": "This is a high-income, limited diversity neighborhood that is family-friendly. Residents are highly educated and typically have 24.5 minute commutes, primarily by car.",
  "moveflow_score": {
    "score": 78,
    "rating": "Very Good",
    "components": {
      "economic": 22.5,
      "education": 22.7,
      "lifestyle": 15.3,
      "mobility": 18.4
    }
  }
}`}
                />
              </Section>
            </>
          ) : (
            <DeveloperContactForm />
          )}
        </DeveloperContainer>
      </DeveloperPageWrapper>
    </Layout>
  );
};

export default Developer; 