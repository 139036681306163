import axios from 'axios';

// Cache for storing fetched data
const cache = {
  States: null,
  PUMA: {},
  'Block Groups': {},
  Blocks: {}
};

// Cache expiration times (in milliseconds)
const CACHE_EXPIRATION = {
  States: 24 * 60 * 60 * 1000, // 24 hours for states
  PUMA: 60 * 60 * 1000,        // 1 hour for PUMAs
  'Block Groups': 30 * 60 * 1000, // 30 minutes for block groups
  Blocks: 15 * 60 * 1000       // 15 minutes for blocks
};

// Cache timestamps
const cacheTimestamps = {
  States: null,
  PUMA: {},
  'Block Groups': {},
  Blocks: {}
};

// Generate a cache key from polygon coordinates
// This improved version creates a more robust spatial hash for better caching
const generateCacheKey = (polygon) => {
  if (!polygon || !polygon.length) return 'default';
  
  // For States level, we always use the same key
  if (window.currentLevelOfDetail === 'States') {
    return 'usa_states';
  }
  
  // Calculate the bounding box of the polygon
  let minLat = Infinity, maxLat = -Infinity, minLng = Infinity, maxLng = -Infinity;
  
  for (const point of polygon) {
    minLat = Math.min(minLat, point[0]);
    maxLat = Math.max(maxLat, point[0]);
    minLng = Math.min(minLng, point[1]);
    maxLng = Math.max(maxLng, point[1]);
  }
  
  // Round to a grid based on the level of detail
  // More detailed levels get finer grid resolution
  let gridSize;
  switch (window.currentLevelOfDetail) {
    case 'PUMA':
      gridSize = 0.5; // 0.5 degree grid for PUMAs
      break;
    case 'Block Groups':
      gridSize = 0.1; // 0.1 degree grid for Block Groups
      break;
    case 'Blocks':
      gridSize = 0.01; // 0.01 degree grid for Blocks
      break;
    default:
      gridSize = 1.0; // 1 degree grid for other levels
  }
  
  // Round the bounding box to the grid
  const roundedMinLat = Math.floor(minLat / gridSize) * gridSize;
  const roundedMaxLat = Math.ceil(maxLat / gridSize) * gridSize;
  const roundedMinLng = Math.floor(minLng / gridSize) * gridSize;
  const roundedMaxLng = Math.ceil(maxLng / gridSize) * gridSize;
  
  // Create a key that represents the grid cells covered by this polygon
  return `${roundedMinLat.toFixed(2)}_${roundedMinLng.toFixed(2)}_${roundedMaxLat.toFixed(2)}_${roundedMaxLng.toFixed(2)}_${window.currentLevelOfDetail}`;
};

// Check if cached data is still valid
const isCacheValid = (level, key = 'default') => {
  if (level === 'States') {
    return cache.States && cacheTimestamps.States && 
           (Date.now() - cacheTimestamps.States < CACHE_EXPIRATION.States);
  }
  
  return cache[level][key] && cacheTimestamps[level][key] && 
         (Date.now() - cacheTimestamps[level][key] < CACHE_EXPIRATION[level]);
};

// Helper function to show notifications if available
const showNotification = (message, type = 'info', duration = 3000) => {
  // Always log to console for debugging
  console.log(`[${type.toUpperCase()}] ${message}`);
  
  // Only show visual notifications if debug mode is enabled
  if (window.debugNotifications && window.notificationManager) {
    window.notificationManager[type](message, duration);
  }
};

// API base URL from environment variable
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.dashboard.moveflow.tech';

/**
 * Debug function to inspect the cache state
 * This can be called from the browser console: window.debugDemographicCache()
 */
export const debugCache = () => {
  console.group('Demographic Cache Debug Info');
  console.log('Current cache state:', JSON.parse(JSON.stringify(cache)));
  console.log('Cache timestamps:', JSON.parse(JSON.stringify(cacheTimestamps)));
  console.log('Current level of detail:', window.currentLevelOfDetail);
  
  // Check expiration for each cache entry
  console.group('Cache Expiration Status');
  if (cache.States) {
    const stateExpired = Date.now() - cacheTimestamps.States > CACHE_EXPIRATION.States;
    console.log(`States cache: ${stateExpired ? 'EXPIRED' : 'VALID'}`);
  }
  
  for (const level of ['PUMA', 'Block Groups', 'Blocks']) {
    console.group(`${level} Cache Entries`);
    for (const key in cache[level]) {
      const expired = Date.now() - cacheTimestamps[level][key] > CACHE_EXPIRATION[level];
      console.log(`Key: ${key} - ${expired ? 'EXPIRED' : 'VALID'}`);
      console.log(`Features: ${cache[level][key]?.features?.length || 0}`);
    }
    console.groupEnd();
  }
  console.groupEnd();
  
  console.groupEnd();
  return 'Cache debug info logged to console';
};

// Expose debug function to window for console access
if (typeof window !== 'undefined') {
  window.debugDemographicCache = debugCache;
  
  // Debug notifications are disabled by default
  window.debugNotifications = false;
  
  // Function to toggle debug notifications
  window.toggleDebugNotifications = (enable = true) => {
    window.debugNotifications = enable;
    console.log(`Debug notifications ${enable ? 'enabled' : 'disabled'}`);
    return `Debug notifications ${enable ? 'enabled' : 'disabled'}`;
  };
}

/**
 * Fetch demographic data by region and polygon
 * @param {string} level - Level of detail (States, PUMA, Block Groups, Blocks)
 * @param {Array} polygon - Array of [lat, lng] coordinates defining the bounding polygon
 * @returns {Promise<Object>} GeoJSON data
 */
export const fetchDemographicsByRegion = async (level, polygon) => {
  try {
    // Store the current level of detail in window for the cache key generation
    window.currentLevelOfDetail = level;
    
    const cacheKey = generateCacheKey(polygon);
    console.log(`Generated cache key for ${level}: ${cacheKey}`);
    
    // Check cache first
    if (level === 'States') {
      if (isCacheValid(level)) {
        showNotification(`Using cached state data`, 'success', 2000);
        console.log('Using cached state data');
        return cache.States;
      }
    } else if (isCacheValid(level, cacheKey)) {
      showNotification(`Using cached ${level} data`, 'success', 2000);
      console.log(`Using cached ${level} data with key: ${cacheKey}`);
      return cache[level][cacheKey];
    }
    
    // If not in cache or cache expired, fetch from API
    showNotification(`Fetching ${level} data from API...`, 'info');
    console.log(`Fetching ${level} data from API with polygon:`, polygon);
    
    // Prepare request data based on the demographics.js file
    const requestData = {
      zoom_level: level,
      polygon: polygon
    };
    
    // Make the POST request to the demographics endpoint
    const response = await axios.post(
      `${API_BASE_URL}/socioeconomics/demographics`,
      requestData
    );
    
    const data = response.data;
    console.log(`Received ${level} data with ${data?.features?.length || 0} features`);
    
    // Cache the result
    if (level === 'States') {
      cache.States = data;
      cacheTimestamps.States = Date.now();
      showNotification(`State data cached for 24 hours`, 'success', 2000);
    } else {
      cache[level][cacheKey] = data;
      cacheTimestamps[level][cacheKey] = Date.now();
      
      // Get cache duration in hours/minutes
      let duration = '';
      if (CACHE_EXPIRATION[level] >= 60 * 60 * 1000) {
        duration = `${CACHE_EXPIRATION[level] / (60 * 60 * 1000)} hours`;
      } else {
        duration = `${CACHE_EXPIRATION[level] / (60 * 1000)} minutes`;
      }
      
      showNotification(`${level} data cached for ${duration}`, 'success', 2000);
    }
    
    return data;
  } catch (error) {
    console.error(`Error fetching ${level} demographic data:`, error);
    showNotification(`Error fetching ${level} data: ${error.message}`, 'error', 5000);
    
    // Log detailed error information for debugging
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Error request:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message:', error.message);
    }
    
    // If API call fails but we have cached data, use it even if expired
    if (level === 'States' && cache.States) {
      showNotification('Using expired cached state data due to API error', 'warning', 3000);
      return cache.States;
    } else if (cache[level] && cache[level][generateCacheKey(polygon)]) {
      showNotification(`Using expired cached ${level} data due to API error`, 'warning', 3000);
      return cache[level][generateCacheKey(polygon)];
    }
    
    // If no cached data is available, return mock data
    return getMockData(level);
  }
};

/**
 * Fetch population data for a specific region
 * @param {Object} params - Parameters for the API call
 * @returns {Promise<Object>} Population data
 */
export const fetchPopulationData = async (params) => {
  try {
    showNotification('Fetching population data...', 'info');
    
    // Use the correct endpoint from demographics.js
    const url = `${API_BASE_URL}/socioeconomics/population`;
    const response = await axios.get(url, { params });
    
    showNotification('Population data downloaded successfully', 'success');
    return response.data;
  } catch (error) {
    console.error('Error fetching population data:', error);
    showNotification(`Error fetching population data: ${error.message}`, 'error', 5000);
    throw error;
  }
};

/**
 * Convert data to CSV format
 * @param {Object} data - Data to convert
 * @returns {string} CSV string
 */
export const convertToCSV = (data) => {
  if (!data || !data.length) {
    return 'No data available';
  }
  
  // Define headers based on demographics.js
  const headers = ['block', 'age', 'hh_income', 'lang', 'hh_size', 'race', 
                  'typical_commute_time', 'school_attending', 'edu',
                  'industry', 'lat', 'lng', 'hh_id'];
  
  // Create CSV header row
  const csvRows = [headers.join(',')];
  
  // Add data rows
  for (const row of data) {
    const values = headers.map(header => {
      const value = row[header];
      // Handle strings with commas by wrapping in quotes
      return typeof value === 'string' && value.includes(',') 
        ? `"${value}"` 
        : (value !== undefined ? value : '');
    });
    csvRows.push(values.join(','));
  }
  
  return csvRows.join('\n');
};

/**
 * Download CSV data as a file
 * @param {string} csvData - CSV data string
 * @param {string} filename - Name of the file to download
 */
export const downloadCSV = (csvData, filename) => {
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  showNotification(`Downloaded ${filename} successfully`, 'success');
};

/**
 * Preload state-level data to ensure it's always available
 */
export const preloadStateData = async () => {
  try {
    if (!cache.States) {
      showNotification('Preloading state-level demographic data...', 'info');
      await fetchDemographicsByRegion('States', []);
    }
  } catch (error) {
    console.error('Failed to preload state data:', error);
    showNotification('Failed to preload state data', 'warning', 3000);
  }
};

/**
 * Get mock data for testing when API is not available
 * @param {string} level - Level of detail
 * @returns {Object} Mock GeoJSON data
 */
const getMockData = (level) => {
  console.log(`Generating mock data for level: ${level}`);
  
  // For state level, create multiple state features
  if (level === 'States') {
    return {
      type: 'FeatureCollection',
      features: [
        // California
        {
          type: 'Feature',
          properties: {
            name: 'California',
            state_name: 'California',
            state_code: 'CA',
            total_population: 39538223,
            num_households: 13157873,
            median_income: 78672,
            block_count: 1500,
            age_groups: [
              { group: '0-17', count: 9000000 },
              { group: '18-34', count: 11000000 },
              { group: '35-54', count: 10500000 },
              { group: '55+', count: 9038223 }
            ],
            student_school_levels: [
              { group: 'Elementary', count: 1800000 },
              { group: 'Middle School', count: 1200000 },
              { group: 'High School', count: 1500000 },
              { group: 'College', count: 2500000 }
            ]
          },
          geometry: {
            type: 'Polygon',
            coordinates: [[
              [-124.4, 32.5],
              [-114.1, 32.5],
              [-114.1, 42.0],
              [-124.4, 42.0],
              [-124.4, 32.5]
            ]]
          }
        },
        // Texas
        {
          type: 'Feature',
          properties: {
            name: 'Texas',
            state_name: 'Texas',
            state_code: 'TX',
            total_population: 29145505,
            num_households: 10000000,
            median_income: 64034,
            block_count: 1200,
            age_groups: [
              { group: '0-17', count: 7500000 },
              { group: '18-34', count: 8000000 },
              { group: '35-54', count: 7500000 },
              { group: '55+', count: 6145505 }
            ],
            student_school_levels: [
              { group: 'Elementary', count: 1500000 },
              { group: 'Middle School', count: 1000000 },
              { group: 'High School', count: 1200000 },
              { group: 'College', count: 1800000 }
            ]
          },
          geometry: {
            type: 'Polygon',
            coordinates: [[
              [-106.6, 25.8],
              [-93.5, 25.8],
              [-93.5, 36.5],
              [-106.6, 36.5],
              [-106.6, 25.8]
            ]]
          }
        },
        // New York
        {
          type: 'Feature',
          properties: {
            name: 'New York',
            state_name: 'New York',
            state_code: 'NY',
            total_population: 20201249,
            num_households: 7500000,
            median_income: 72108,
            block_count: 900,
            age_groups: [
              { group: '0-17', count: 4000000 },
              { group: '18-34', count: 5500000 },
              { group: '35-54', count: 5200000 },
              { group: '55+', count: 5501249 }
            ],
            student_school_levels: [
              { group: 'Elementary', count: 900000 },
              { group: 'Middle School', count: 700000 },
              { group: 'High School', count: 800000 },
              { group: 'College', count: 1500000 }
            ]
          },
          geometry: {
            type: 'Polygon',
            coordinates: [[
              [-79.8, 40.5],
              [-71.8, 40.5],
              [-71.8, 45.0],
              [-79.8, 45.0],
              [-79.8, 40.5]
            ]]
          }
        }
      ]
    };
  }
  
  // For other levels, return a single feature
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          name: `Mock ${level} Data`,
          total_population: 10000,
          num_households: 3500,
          median_income: 65000,
          block_count: 10,
          age_groups: [
            { group: '0-17', count: 2000 },
            { group: '18-34', count: 3000 },
            { group: '35-54', count: 3000 },
            { group: '55+', count: 2000 }
          ],
          student_school_levels: [
            { group: 'Elementary', count: 800 },
            { group: 'Middle School', count: 600 },
            { group: 'High School', count: 700 },
            { group: 'College', count: 1200 }
          ],
          // Optional properties that might be used
          income_histogram: [
            20000, 35000, 42000, 55000, 68000, 75000, 82000, 95000, 110000, 125000
          ]
        },
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [-96.8, 32.7],
            [-96.7, 32.7],
            [-96.7, 32.8],
            [-96.8, 32.8],
            [-96.8, 32.7]
          ]]
        }
      }
    ]
  };
}; 