import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import heroImage from '../images/mf_LP_02.png';
import theme from '../theme';

const HeroContainer = styled.section`
  width: 100%;
  box-sizing: border-box;
  background-color: ${theme.colors.background};
  padding: ${theme.space[4]} 0;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: ${theme.space[5]} 0;
  }
`;

const HeroContent = styled.div`
  max-width: ${theme.sizes.container};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${theme.space[4]};
  
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: 0 ${theme.space[4]};
  }
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const HeroTextContent = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 0;
  box-sizing: border-box;
  text-align: left;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    width: 50%;
    padding-right: ${theme.space[4]};
  }
`;

const HeroImageContainer = styled.div`
  display: block;
  width: 100%;
  margin: ${theme.space[4]} auto 0;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    width: 33.333%;
    position: relative;
    margin: 0 ${theme.space[4]};
  }
`;

const HeroImageElement = styled.img`
  width: 100%;
  height: auto;
  border-radius: ${theme.radii.button};
  box-shadow: none;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: ${theme.space[3]};
  color: ${theme.colors.text};
  font-weight: ${theme.typography.fontWeights.extrabold};
  line-height: ${theme.typography.lineHeights.tight};
  letter-spacing: ${theme.typography.letterSpacings.tight};
  font-family: ${theme.typography.fontFamily.secondary};
  
  @media (min-width: ${theme.breakpoints.small}) {
    font-size: 3.5rem;
  }
`;

const HeroSubhead = styled.p`
  font-size: 1.1rem;
  line-height: ${theme.typography.lineHeights.normal};
  margin-bottom: ${theme.space[4]};
  color: ${theme.colors.text};
  font-weight: ${theme.typography.fontWeights.medium};
  letter-spacing: ${theme.typography.letterSpacings.tight};
  font-family: ${theme.typography.fontFamily.secondary};
  padding-bottom: 10px;
  
  @media (min-width: ${theme.breakpoints.small}) {
    font-size: 1.3rem;
  }
`;

const HeroKicker = styled.p`
  font-size: 0.9rem;
  font-weight: ${theme.typography.fontWeights.bold};
  color: ${theme.colors.text};
  margin-bottom: ${theme.space[2]};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-family: ${theme.typography.fontFamily.secondary};
  
  @media (min-width: ${theme.breakpoints.small}) {
    font-size: 1rem;
  }
`;

const HeroLink = styled(Link)`
  display: inline-block;
  background-color: ${theme.colors.components.heroButton};
  color: ${theme.colors.background};
  padding: 0.75rem 1.75rem;
  border-radius: ${theme.radii.button};
  text-decoration: none;
  font-weight: ${theme.typography.fontWeights.semibold};
  font-size: ${theme.typography.fontSizes.md};
  transition: ${theme.transitions.default};
  box-shadow: ${theme.shadows.button};
  font-family: ${theme.typography.fontFamily.secondary};
  
  &:hover {
    background-color: ${theme.colors.components.heroButtonHover};
    box-shadow: ${theme.shadows.buttonHover};
    text-decoration: none;
    color: ${theme.colors.background};
  }
`;

const Hero = ({ kicker, h1, subhead, links }) => {
  console.log("PropelAuth URL:", process.env.REACT_APP_PROPELAUTH_AUTH_URL);

  return (
    <HeroContainer>
      <HeroContent>
        <HeroWrapper>
          <HeroTextContent>
            {kicker && <HeroKicker>{kicker}</HeroKicker>}
            <HeroTitle>
              {h1}
            </HeroTitle>
            <HeroSubhead>
              {subhead}
            </HeroSubhead>
            
            {links && links.length > 0 && (
              <HeroLink to={links[0].href.startsWith('http') ? { pathname: links[0].href } : links[0].href} target={links[0].href.startsWith('http') ? "_blank" : ""} rel={links[0].href.startsWith('http') ? "noopener noreferrer" : ""}>
                {links[0].text}
              </HeroLink>
            )}
          </HeroTextContent>
          
          <HeroImageContainer>
            <HeroImageElement src={heroImage} alt="Moveflow Population Intelligence Dashboard" />
          </HeroImageContainer>
        </HeroWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero; 