import React from 'react';
import styled from 'styled-components';
import feature1 from '../images/features/f01.png';
import feature2 from '../images/features/f02.png';
import feature3 from '../images/features/f03.png';
import feature4 from '../images/features/f04.png';
import feature5 from '../images/features/01_strength.png';

import theme from '../theme';

const LocationIntelligenceContainer = styled.section`
  width: 100%;
  box-sizing: border-box;
  background-color: #FAF0DC;
  padding: ${theme.space[4]} 0;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: ${theme.space[5]} 0;
  }
`;

const LocationIntelligenceContent = styled.div`
  max-width: ${theme.sizes.container};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${theme.space[4]};
  
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: 0 ${theme.space[4]};
  }
`;

const LocationIntelligenceHeader = styled.div`
  background-color: #0C423E;
  color: ${theme.colors.background};
  padding: ${theme.space[5]} ${theme.space[4]};
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border-radius: ${theme.radii.large} ${theme.radii.large} 0 0;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: ${theme.space[5]} ${theme.space[5]};
  }
`;

const LocationIntelligenceWrapper = styled.div`
  max-width: ${theme.sizes.tight};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LocationIntelligenceTitle = styled.h2`
  font-size: 32px;
  margin-bottom: ${theme.space[3]};
  color: ${theme.colors.background};
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: -0.02em;
  font-family: "DM Sans", sans-serif;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: 40px;
  }
`;

const LocationIntelligenceText = styled.p`
  font-size: 16px;
  line-height: 1.65;
  margin-bottom: ${theme.space[4]};
  color: #FAF0DC;
  font-weight: 400;
  letter-spacing: -0.02em;
  font-family: "DM Sans", sans-serif;
  max-width: 800px;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
  }
`;

const FeaturesContainer = styled.div`
  background-color: #0C423E;
  padding: ${theme.space[5]} ${theme.space[4]};
  border-radius: 0 0 ${theme.radii.large} ${theme.radii.large};
  
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: ${theme.space[5]} ${theme.space[5]};
  }
`;

const FeatureSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: 0 auto ${theme.space[5]};
  
  @media (min-width: ${theme.breakpoints.medium}) {
    flex-direction: ${props => props.flip ? 'row-reverse' : 'row'};
    margin-bottom: ${theme.space[5]};
    align-items: center;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const FeatureContent = styled.div`
  color: ${theme.colors.background};
  padding: ${theme.space[4]} 0;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    width: 50%;
    padding: ${props => props.flip ? `${theme.space[4]} 0 ${theme.space[4]} ${theme.space[5]}` : `${theme.space[4]} ${theme.space[5]} ${theme.space[4]} 0`};
  }
`;

const FeatureImageContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    width: 50%;
  }
`;

const FeatureImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 400px;
`;

const FeatureSectionTitle = styled.h3`
  font-size: 14px;
  margin-bottom: ${theme.space[2]};
  color: #FAF0DC;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: 16px;
  }
`;

const FeatureTitle = styled.h3`
  font-size: 24px;
  margin-bottom: ${theme.space[3]};
  color: ${theme.colors.background};
  font-weight: 700;
  line-height: 1.25;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: 28px;
  }
`;

const FeatureDescription = styled.p`
  font-size: 16px;
  line-height: 1.65;
  color: #FAF0DC;
  margin-bottom: ${theme.space[3]};
  
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
  }
`;

const LocationIntelligence = ({ heading, text }) => {
  const features = [
    {
      id: 'feature1',
      sectionTitle: 'RECOMMENDATIONS FOR ACTION',
      title: "Let's act now.",
      description: "At Moveflow, we understand that the world is constantly changing and evolving. That's why we provide location-specific predictions to help businesses adapt and evolve. With our powerful insights, you can make better business decisions that lead to the discovery of new opportunities.",
      image: feature1
    },
    {
      id: 'feature2',
      sectionTitle: 'RESILIENCE',
      title: 'It is easy to prepare yourself.',
      description: "Anything can happen at any time. Moveflow helps you understand how your business can behave in different situations and thus protects you from risks affecting your services and products. Moveflow provides you with a suite of tools to analyze the performance of your business against a variety of what-if scenarios. Preparing for what might come helps you to excel, succeed, and profit.",
      image: feature2
    },
    {
      id: 'feature3',
      sectionTitle: 'PLANNING & PREFERENCES',
      title: 'Know what matters',
      description: "Data quality significantly determines the correct valuation of objects and reliable recommendations for action. With our Customer Preferences Monitor, you act proactively in property management and gain business advantages by knowing your customers' preferences. Through a sophisticated understanding of human behavior, you'll understand how retail space can be designed and utilized optimally, while determining what purchasing power is available at your points of interest.",
      image: feature3
    },
    {
      id: 'feature4',
      sectionTitle: 'OPTIMIZATION',
      title: 'Use your data properly',
      description: "We put your data about the target audience and location in context. By understanding the relationship between location and target audience, you can make informed resource planning decisions and drive growth for your business. Our Moveflow products provide the insights you need to make data-driven decisions that respond at the same speed as your data pool changes in daily business.",
      image: feature4
    }
  ];

  return (
    <LocationIntelligenceContainer>
      <LocationIntelligenceContent>
        <LocationIntelligenceHeader>
          <LocationIntelligenceWrapper>
            <LocationIntelligenceTitle>
              {heading || "Location intelligence to accelerate your business"}
            </LocationIntelligenceTitle>
            <LocationIntelligenceText>
              {text || "Moveflow provides your business a valuable edge in a challenging and changing environment."}
            </LocationIntelligenceText>
          </LocationIntelligenceWrapper>
        </LocationIntelligenceHeader>
        
        <FeaturesContainer>
          {features.map((feature, index) => (
            <FeatureSection key={feature.id} flip={index % 2 === 1}>
              <FeatureContent flip={index % 2 === 1}>
                <FeatureSectionTitle>{feature.sectionTitle}</FeatureSectionTitle>
                <FeatureTitle>{feature.title}</FeatureTitle>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </FeatureContent>
              <FeatureImageContainer>
                <FeatureImage src={feature.image} alt={feature.title} />
              </FeatureImageContainer>
            </FeatureSection>
          ))}
        </FeaturesContainer>
      </LocationIntelligenceContent>
    </LocationIntelligenceContainer>
  );
};

export default LocationIntelligence; 