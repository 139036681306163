import React from 'react';
import styled from 'styled-components';

const FeatureCard = styled.div`
  background-color: black;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
`;

const FeatureImage = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;
  display: block;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const FeatureContent = styled.div`
  padding: 1.5rem;
  box-sizing: border-box;
  width: 100%;
`;

const FeatureKicker = styled.p`
  font-size: 1rem;
  color: #0C423E;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const FeatureHeading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #322862;
  font-weight: 700;
  line-height: 1.3;
  
  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
`;

const FeatureText = styled.div`
  color: #322862;
  line-height: 1.7;
  
  p {
    margin-bottom: 1rem;
    font-size: 1.05rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Feature = ({ kicker, heading, children, image }) => {
  return (
    <FeatureCard>
      {image && (
        <FeatureImage 
          src={image.src} 
          alt={image.alt || 'Feature image'} 
        />
      )}
      
      <FeatureContent>
        <FeatureKicker>{kicker}</FeatureKicker>
        <FeatureHeading>{heading}</FeatureHeading>
        <FeatureText>
          {children && children.map((child) => (
            <p key={child.id}>{child.textContent}</p>
          ))}
        </FeatureText>
      </FeatureContent>
    </FeatureCard>
  );
};

export default Feature; 