import colors from './colors';

/**
 * Application theme
 * This file centralizes all theme constants used throughout the application
 */

const theme = {
  // Colors from colors.js
  colors,
  
  // Spacing scale
  space: {
    0: "0",
    1: "4px",
    2: "8px",
    3: "16px",
    4: "32px",
    5: "64px",
    6: "128px",
  },
  
  // Container sizes
  sizes: {
    container: "1280px",
    narrow: "1024px",
    tight: "848px",
  },
  
  // Breakpoints for responsive design
  breakpoints: {
    small: "40em",
    medium: "52em",
    large: "64em"
  },
  
  // Border radius
  radii: {
    button: "10px",
    large: "24px",
  },
  
  // Typography
  typography: {
    fontFamily: {
      primary: '"DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
      secondary: '"DM Sans", sans-serif',
    },
    fontSizes: {
      xs: '0.75rem',
      sm: '0.875rem',
      md: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
    },
    fontWeights: {
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
    lineHeights: {
      none: 1,
      tight: 1.25,
      normal: 1.5,
      relaxed: 1.65,
    },
    letterSpacings: {
      tight: '-0.02em',
      normal: '0',
      wide: '0.08em',
    },
  },
  
  // Shadows
  shadows: {
    sm: '0 1px 3px rgba(0, 0, 0, 0.1)',
    md: '0 2px 6px rgba(0, 0, 0, 0.1)',
    lg: '0 4px 10px rgba(0, 0, 0, 0.15)',
    button: '0 2px 6px rgba(255, 104, 100, 0.3)',
    buttonHover: '0 4px 10px rgba(255, 104, 100, 0.4)',
  },
  
  // Transitions
  transitions: {
    default: 'all 0.2s ease',
    fast: 'all 0.1s ease',
    slow: 'all 0.3s ease',
  }
};

export default theme; 