import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AgeDistributionChart, IncomeDistributionChart, SchoolLevelsChart } from './DemographicCharts';
import theme from '../theme';

const PanelContainer = styled.div`
  background: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 20px;
  box-shadow: ${theme.shadows.lg};
  width: 350px;
  max-height: 75vh;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  
  @media (max-width: 768px) {
    width: 90%;
    max-width: 90%;
    height: 65vh;
    max-height: 65vh;
    padding: 16px 16px 70px 16px;
    margin: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    border-radius: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    background: rgba(255, 255, 255, 0.98);
    display: flex;
    flex-direction: column;
  }
  
  &:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }
  
  /* Add scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
    
    @media (max-width: 768px) {
      width: 4px;
    }
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
`;

const PanelTitle = styled.h3`
  margin: 0 0 15px 0;
  color: ${theme.colors.text};
  position: relative;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  
  @media (max-width: 768px) {
    font-size: 16px;
    margin: 0 0 12px 0;
    padding-bottom: 8px;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 3px;
    background: linear-gradient(90deg, ${theme.colors.primary}, ${theme.colors.active});
    border-radius: 3px;
    
    @media (max-width: 768px) {
      width: 30px;
      height: 2px;
    }
  }
`;

const SectionTitle = styled.h4`
  margin: 15px 0 8px 0;
  color: ${theme.colors.text};
  font-size: 16px;
  font-weight: 600;
  
  @media (max-width: 768px) {
    font-size: 14px;
    margin: 10px 0 6px 0;
  }
`;

const StatLine = styled.p`
  margin: 8px 0;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 768px) {
    font-size: 12px;
    margin: 5px 0;
  }
  
  strong {
    font-weight: ${theme.typography.fontWeights.semibold};
    color: ${theme.colors.textSecondary};
  }
  
  span {
    font-weight: ${theme.typography.fontWeights.medium};
  }
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  margin: 15px 0;
  
  @media (max-width: 768px) {
    margin: 8px 0;
  }
`;

const DownloadButton = styled.button`
  padding: 10px 16px;
  background: ${theme.colors.primary};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${theme.typography.fontWeights.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  
  @media (max-width: 768px) {
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 6px;
  }
  
  &:hover {
    background: ${theme.colors.active};
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const DownloadSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  
  @media (max-width: 768px) {
    gap: 5px;
    margin-top: 8px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BlockInfo = styled.span`
  font-size: 14px;
  
  @media (max-width: 768px) {
    font-size: 11px;
    margin-bottom: 5px;
  }
`;

const CloseButton = styled.button`
  padding: 12px 16px;
  background: ${theme.colors.primary};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${theme.typography.fontWeights.medium};
  display: none;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  width: 100%;
  margin-top: 10px;
  
  @media (max-width: 768px) {
    display: flex;
    padding: 12px;
    font-size: 14px;
    border-radius: 8px;
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(90% - 32px);
    margin: 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  &:hover {
    background: ${theme.colors.active};
    transform: translateY(-2px);
    
    @media (max-width: 768px) {
      transform: translateX(-50%) translateY(-2px);
    }
  }
  
  &:active {
    transform: translateY(0);
    
    @media (max-width: 768px) {
      transform: translateX(-50%) translateY(0);
    }
  }
`;

const PanelContent = styled.div`
  @media (max-width: 768px) {
    padding-top: 0;
    overflow-y: auto;
    flex: 1;
  }
`;

/**
 * Component to display detailed demographic information
 */
const DemographicPanel = ({ properties, isLoading, onDownload, onClose }) => {
  const [, forceUpdate] = useState();
  const isMobile = window.innerWidth <= 768;
  
  // Add resize listener to handle orientation changes on mobile
  useEffect(() => {
    const handleResize = () => forceUpdate({});
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Disable body scrolling when panel is shown on mobile
  useEffect(() => {
    const isMobile = window.innerWidth <= 768;
    
    if (properties && isMobile) {
      // Disable scrolling on body
      document.body.style.overflow = 'hidden';
      
      // Re-enable scrolling when component unmounts
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [properties]);
  
  if (!properties) return null;
  
  // Helper function to safely format numbers
  const formatNumber = (value) => {
    return value !== undefined && value !== null ? value.toLocaleString() : 'N/A';
  };
  
  return (
    <PanelContainer>
      <PanelTitle>Socioeconomics</PanelTitle>
      
      <PanelContent>
        <StatLine>
          <strong>Total Population:</strong> 
          <span>{formatNumber(properties.total_population)}</span>
        </StatLine>
        
        <StatLine>
          <strong>Number of Households:</strong> 
          <span>{formatNumber(properties.num_households)}</span>
        </StatLine>
        
        {properties.income_histogram ? (
          <>
            <SectionTitle>Income Distribution</SectionTitle>
            <IncomeDistributionChart incomeHistogram={properties.income_histogram} />
          </>
        ) : (
          <StatLine>
            <strong>Median Income:</strong> 
            <span>${formatNumber(properties.median_income)}</span>
          </StatLine>
        )}
        
        <SectionTitle>Age Groups</SectionTitle>
        {properties.age_groups ? (
          <AgeDistributionChart ageGroups={properties.age_groups} />
        ) : (
          <p>Age data not available</p>
        )}
        
        <SectionTitle>Student School Levels</SectionTitle>
        {properties.student_school_levels ? (
          <SchoolLevelsChart schoolLevels={properties.student_school_levels} />
        ) : (
          <p>School level data not available</p>
        )}
        
        <Divider />
        
        <DownloadSection>
          <BlockInfo>
            <strong>Number of Blocks:</strong> {formatNumber(properties.block_count)}
          </BlockInfo>
          {!isMobile && (
            <DownloadButton 
              onClick={onDownload} 
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Download Data'}
            </DownloadButton>
          )}
        </DownloadSection>
      </PanelContent>
      
      {isMobile && (
        <CloseButton onClick={onClose}>
          Close Panel
        </CloseButton>
      )}
    </PanelContainer>
  );
};

export default DemographicPanel; 