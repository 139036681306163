import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * Component for adding structured data to pages for rich search results
 * 
 * @param {Object} props
 * @param {string} props.type - Type of structured data (Organization, Product, etc.)
 * @param {Object} props.data - The structured data object
 */
const StructuredData = ({ type, data }) => {
  // Organization schema for the company
  const organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Moveflow Technologies',
    url: 'https://moveflow.ai',
    logo: 'https://moveflow.ai/logo512.png',
    sameAs: [
      'https://twitter.com/moveflowai',
      'https://linkedin.com/company/moveflow'
    ],
    contactPoint: {
      '@type': 'ContactPoint',
      email: 'info@moveflow.ai',
      contactType: 'customer service'
    },
    description: 'Moveflow transforms demographic data into business intelligence. Our real-time population insights reveal market opportunities that traditional data sources miss.'
  };

  // SoftwareApplication schema for the product
  const softwareSchema = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: 'Moveflow Population Intelligence',
    applicationCategory: 'BusinessApplication',
    operatingSystem: 'Web',
    offers: {
      '@type': 'Offer',
      price: '0',
      priceCurrency: 'USD'
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.8',
      ratingCount: '27'
    },
    description: 'Strategic intelligence platform that decodes population movements and demographic shifts in real time, revealing market opportunities invisible to your competitors.'
  };

  let schemaData;
  
  switch (type) {
    case 'Organization':
      schemaData = { ...organizationSchema, ...data };
      break;
    case 'SoftwareApplication':
      schemaData = { ...softwareSchema, ...data };
      break;
    default:
      schemaData = data;
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
};

export default StructuredData; 