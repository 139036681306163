import React from 'react';
import styled from 'styled-components';
import { Feature } from './';

const FeatureListContainer = styled.div`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const FeaturesGridContainer = styled.div`
  background-color: #FAF0DC;
  padding: 4rem 2rem;
  
  @media (min-width: 768px) {
    padding: 4rem;
  }
  
  @media (min-width: 1024px) {
    padding: 6rem 4rem;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
  
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
  }
`;

const FeatureList = ({ content = [] }) => {
  return (
    <FeatureListContainer>
      <FeaturesGridContainer>
        <FeaturesGrid>
          {content.map((feature, i) => (
            <Feature key={feature.id} {...feature} />
          ))}
        </FeaturesGrid>
      </FeaturesGridContainer>
    </FeatureListContainer>
  );
};

export default FeatureList; 