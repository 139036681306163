import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { LoadingComponent } from '../components';

const NotFoundContainer = styled.div`
  text-align: center;
  padding: 4rem 2rem;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NotFoundTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #0C423E;
`;

const NotFoundText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #555;
`;

const HomeLink = styled(Link)`
  display: inline-block;
  background-color: #0C423E;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #0a3632;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const NotFound = () => {
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    
    return () => clearTimeout(timer);
  }, []);
  
  if (loading) {
    return <LoadingComponent fullPage />;
  }
  
  return (
    <Layout title="Page Not Found">
      <NotFoundContainer>
        <NotFoundTitle>404</NotFoundTitle>
        <NotFoundText>Oops! The page you're looking for doesn't exist.</NotFoundText>
        <HomeLink to="/">Go back to homepage</HomeLink>
      </NotFoundContainer>
    </Layout>
  );
};

export default NotFound; 