import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * SEO component for managing document head metadata
 * 
 * @param {Object} props
 * @param {string} props.title - Page title
 * @param {string} props.description - Page description
 * @param {string} props.keywords - Comma-separated keywords
 * @param {string} props.path - Current path (without domain)
 * @param {string} props.image - Image path for social sharing
 */
const SEO = ({ 
  title = 'Population Intelligence for Strategic Decisions', 
  description = 'Transform demographic data into business advantage. Moveflow delivers real-time population insights that reveal market opportunities your competitors miss.',
  keywords = 'population intelligence, demographic insights, market analysis, location strategy, business intelligence, demographic shifts, population trends, competitive advantage',
  path = '',
  image = '/og-image.jpg'
}) => {
  const siteUrl = 'https://moveflow.ai';
  const fullTitle = `Moveflow - ${title}`;
  const url = `${siteUrl}${path}`;
  const imageUrl = `${siteUrl}${image}`;

  return (
    <Helmet>
      {/* Basic metadata */}
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={fullTitle} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
    </Helmet>
  );
};

export default SEO; 