import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import theme from '../theme';

// Animation for sliding in from the top
const slideIn = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Animation for sliding out to the top
const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

// Notification container
const NotificationContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 2000;
  max-width: 350px;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  backdrop-filter: blur(5px);
  animation: ${props => props.isClosing ? slideOut : slideIn} 0.3s ease-in-out forwards;
  
  ${props => {
    switch (props.type) {
      case 'success':
        return css`
          background-color: rgba(76, 175, 80, 0.9);
          color: white;
        `;
      case 'info':
        return css`
          background-color: rgba(33, 150, 243, 0.9);
          color: white;
        `;
      case 'warning':
        return css`
          background-color: rgba(255, 152, 0, 0.9);
          color: white;
        `;
      case 'error':
        return css`
          background-color: rgba(244, 67, 54, 0.9);
          color: white;
        `;
      default:
        return css`
          background-color: rgba(250, 250, 250, 0.9);
          color: ${theme.colors.text};
        `;
    }
  }}
`;

// Icon container
const IconContainer = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Message text
const Message = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
`;

// Close button
const CloseButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  opacity: 0.7;
  cursor: pointer;
  font-size: 18px;
  padding: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    opacity: 1;
  }
`;

// Progress bar for auto-dismiss
const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.5);
  width: ${props => props.progress}%;
  border-radius: 0 0 0 8px;
  transition: width 0.1s linear;
`;

/**
 * Notification component for displaying temporary messages
 * @param {Object} props - Component props
 * @param {string} props.message - The notification message
 * @param {string} props.type - Notification type (success, info, warning, error)
 * @param {number} props.duration - Duration in milliseconds before auto-dismiss
 * @param {function} props.onClose - Function to call when notification is closed
 */
const Notification = ({ 
  message, 
  type = 'info', 
  duration = 3000, 
  onClose 
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  
  // Handle close button click
  const handleClose = () => {
    setIsClosing(true);
    if (intervalId) {
      clearInterval(intervalId);
    }
    
    // Wait for animation to complete before calling onClose
    setTimeout(() => {
      if (onClose) onClose();
    }, 300);
  };
  
  // Set up auto-dismiss timer and progress bar
  useEffect(() => {
    if (duration > 0) {
      // Calculate interval for updating progress bar (update every 50ms)
      const interval = setInterval(() => {
        setProgress(prev => {
          const newProgress = prev + (50 / duration) * 100;
          if (newProgress >= 100) {
            clearInterval(interval);
            handleClose();
            return 100;
          }
          return newProgress;
        });
      }, 50);
      
      setIntervalId(interval);
      
      return () => clearInterval(interval);
    }
  }, [duration]);
  
  // Get icon based on notification type
  const getIcon = () => {
    switch (type) {
      case 'success':
        return '✓';
      case 'info':
        return 'ℹ';
      case 'warning':
        return '⚠';
      case 'error':
        return '✕';
      default:
        return 'ℹ';
    }
  };
  
  return (
    <NotificationContainer type={type} isClosing={isClosing}>
      <IconContainer>{getIcon()}</IconContainer>
      <Message>{message}</Message>
      <CloseButton onClick={handleClose}>×</CloseButton>
      {duration > 0 && <ProgressBar progress={progress} />}
    </NotificationContainer>
  );
};

export default Notification; 