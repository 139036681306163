import React from 'react';
import styled from 'styled-components';
import ctaImage from '../images/build_CTA_02.png';
import theme from '../theme';

const CtaContainer = styled.section`
  width: 100%;
  box-sizing: border-box;
  background-color: ${theme.colors.background};
  padding: ${theme.space[4]} 0;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: ${theme.space[5]} 0;
  }
`;

const CtaContent = styled.div`
  max-width: ${theme.sizes.container};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${theme.space[4]};
  
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: 0 ${theme.space[4]};
  }
`;

const CtaBox = styled.div`
  background-color: ${theme.colors.components.ctaBox};
  color: ${theme.colors.background};
  padding: ${theme.space[5]} ${theme.space[4]};
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border-radius: ${theme.radii.large};
  
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: ${theme.space[5]} ${theme.space[5]};
  }
`;

const CtaWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CtaHeader = styled.div`
  text-align: center;
  margin-bottom: ${theme.space[3]};
`;

const CtaImageContainer = styled.div`
  max-width: 500px;
  width: 100%;
  margin: ${theme.space[4]} 0;
  padding-top: ${theme.space[4]};
`;

const CtaImageElement = styled.img`
  width: 100%;
  height: auto;
  border-radius: ${theme.radii.button};
  box-shadow: none;
`;

const CtaKicker = styled.p`
  font-size: ${theme.typography.fontSizes.sm};
  margin-bottom: ${theme.space[2]};
  color: ${theme.colors.background};
  font-weight: ${theme.typography.fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: ${theme.typography.letterSpacings.wide};
  
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: ${theme.typography.fontSizes.md};
  }
`;

const CtaHeading = styled.h2`
  font-size: ${theme.typography.fontSizes['3xl']};
  margin-bottom: ${theme.space[3]};
  color: ${theme.colors.background};
  font-weight: ${theme.typography.fontWeights.extrabold};
  line-height: ${theme.typography.lineHeights.tight};
  letter-spacing: ${theme.typography.letterSpacings.tight};
  font-family: ${theme.typography.fontFamily.secondary};
  
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: ${theme.typography.fontSizes['4xl']};
  }
`;

const CtaText = styled.p`
  font-size: ${theme.typography.fontSizes.md};
  line-height: ${theme.typography.lineHeights.relaxed};
  margin-bottom: 0;
  color: ${theme.colors.background};
  font-weight: ${theme.typography.fontWeights.normal};
  letter-spacing: ${theme.typography.letterSpacings.tight};
  font-family: ${theme.typography.fontFamily.secondary};
  max-width: 800px;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: ${theme.typography.fontSizes.lg};
  }
`;

const CtaButtonContainer = styled.div`
  margin-top: ${theme.space[4]};
  padding-top: ${theme.space[5]};
`;

const CtaLink = styled.a`
  display: inline-block;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  padding: 0.75rem 1.75rem;
  border-radius: ${theme.radii.button};
  text-decoration: none;
  font-weight: ${theme.typography.fontWeights.semibold};
  font-size: ${theme.typography.fontSizes.md};
  transition: ${theme.transitions.default};
  font-family: ${theme.typography.fontFamily.secondary};
  
  &:hover {
    text-decoration: none;
    background-color: ${theme.colors.active};
    color: ${theme.colors.background};
  }
`;

const Cta = ({ kicker, heading, text, links }) => {
  return (
    <CtaContainer>
      <CtaContent>
        <CtaBox>
          <CtaWrapper>
            <CtaHeader>
              {kicker && <CtaKicker>{kicker || "CONTACT US"}</CtaKicker>}
              <CtaHeading>
                {heading || "Gain your competitive edge!"}
              </CtaHeading>
              <CtaText>
                {text || "Time is precious in a rapidly changing world. Call us today, and we will demonstrate how Moveflow will accelerate your business."}
              </CtaText>
            </CtaHeader>
            
            <CtaImageContainer>
              <CtaImageElement src={ctaImage} alt="Moveflow Dashboard" />
            </CtaImageContainer>
            
            <CtaButtonContainer>
              <CtaLink href="contact">
                Request a demo
              </CtaLink>
            </CtaButtonContainer>
          </CtaWrapper>
        </CtaBox>
      </CtaContent>
    </CtaContainer>
  );
};

export default Cta; 