/**
 * Application color palette
 * This file centralizes all color definitions used throughout the application
 * Simplified to match the Gatsby frontend's color scheme
 */

const colors = {
  // Core colors from Gatsby frontend
  background: "#FAF0DC",  // Light beige/cream background
  muted: "#0C423E",       // Teal/dark green
  text: "#322862",        // Dark purple - primary text color
  primary: "#0C423E",     // Teal/dark green - primary brand color
  active: "#ff6864",      // Coral/red - accent color
  black: "#241e1e",       // Dark gray/black
  success: "#4CAF50",     // Light green - success messages
  
  // Additional essential colors
//   white: "#FFFFFF",       // White
  
  // Derived colors for specific components (using the core palette)
  components: {
    heroButton: "#ff6864",
    heroButtonHover: "#0C423E",
    ctaBox: "#ff6864",
  }
};

export default colors; 