import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Notification from './Notification';

// Container for stacking notifications
const NotificationsContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  gap: 10px;
  pointer-events: none;
  
  & > * {
    pointer-events: auto;
  }
`;

/**
 * NotificationManager component for managing multiple notifications
 * @param {Object} props - Component props
 */
const NotificationManager = () => {
  const [notifications, setNotifications] = useState([]);
  
  // Remove a notification by ID
  const removeNotification = useCallback((id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  }, []);
  
  // Add a new notification
  const addNotification = useCallback((message, type = 'info', duration = 3000) => {
    const id = Date.now().toString();
    setNotifications(prev => [...prev, { id, message, type, duration }]);
    return id;
  }, []);
  
  // Expose methods globally
  useEffect(() => {
    window.notificationManager = {
      success: (message, duration) => addNotification(message, 'success', duration),
      info: (message, duration) => addNotification(message, 'info', duration),
      warning: (message, duration) => addNotification(message, 'warning', duration),
      error: (message, duration) => addNotification(message, 'error', duration),
      remove: removeNotification
    };
    
    return () => {
      delete window.notificationManager;
    };
  }, [addNotification, removeNotification]);
  
  return (
    <NotificationsContainer>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          message={notification.message}
          type={notification.type}
          duration={notification.duration}
          onClose={() => removeNotification(notification.id)}
        />
      ))}
    </NotificationsContainer>
  );
};

export default NotificationManager; 