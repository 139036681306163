import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../theme';
import colors from '../theme/colors';

// Main container styles
const ProductsPageWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: ${colors.background};
`;

const ProductsContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 1rem;
  box-sizing: border-box;
`;

// Section styles
const ProductSection = styled.section`
  margin-bottom: 5rem;
  width: 100%;
  box-sizing: border-box;
`;

const ProductTitle = styled.h1`
  font-size: 2.5rem;
  color: ${colors.text};
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  font-family: ${theme.typography.fontFamily.secondary};
  
  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const ProductSubtitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.text};
  margin-bottom: 1rem;
  word-wrap: break-word;
  font-family: ${theme.typography.fontFamily.secondary};
  
  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const ProductText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  color: ${colors.text};
  
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

// Flex container for product cards
const ProductGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
  
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const ProductCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  @media (min-width: 768px) {
    flex: 0 0 calc(50% - 1rem);
  }
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }
`;

const IconContainer = styled.div`
  margin-bottom: 1.5rem;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// API section styles
const ApiSection = styled.div`
  background: white;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 3rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
`;

const ApiHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const ApiTitle = styled.h2`
  font-size: 1.8rem;
  color: ${colors.text};
  margin-left: 1rem;
  font-family: ${theme.typography.fontFamily.secondary};
`;

const ApiFeatureGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-top: 2rem;
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ApiFeature = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ApiFeatureIcon = styled.div`
  margin-right: 1rem;
  color: ${colors.active};
`;

const ApiFeatureText = styled.div`
  flex: 1;
`;

const ApiFeatureTitle = styled.h3`
  font-size: 1.2rem;
  color: ${colors.text};
  margin-bottom: 0.5rem;
  font-family: ${theme.typography.fontFamily.secondary};
`;

const ApiFeatureDescription = styled.p`
  font-size: 1rem;
  color: ${colors.text};
  line-height: 1.5;
`;

// SVG Icons
const MapIcon = () => (
  <svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 6L21 3V18L15 21M15 6V21M15 6L9 9M15 21L9 18M9 9L3 6V18L9 18M9 9V18" stroke={colors.text} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const AnalyticsIcon = () => (
  <svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 16V11M12 16V8M16 16V13M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z" stroke={colors.text} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ApiIcon = () => (
  <svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 8L12 3L7 8M12 3V16M5 21H19" stroke={colors.text} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 16H21" stroke={colors.text} strokeWidth="2" strokeLinecap="round"/>
    <path d="M5 12H8M12 12H16" stroke={colors.text} strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

const DemographicIcon = () => (
  <svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 21V19C17 16.7909 15.2091 15 13 15H5C2.79086 15 1 16.7909 1 19V21M23 21V19C22.9986 17.1771 21.765 15.5857 20 15.13M16 3.13C17.7699 3.58317 19.0078 5.17799 19.0078 7.005C19.0078 8.83201 17.7699 10.4268 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z" stroke={colors.text} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const CheckIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 13L9 17L19 7" stroke={colors.active} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const CodeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 18L22 12L16 6M8 6L2 12L8 18" stroke={colors.text} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

// Main component
const Products = () => {
  return (
    <ProductsPageWrapper>
      <Header />
      <ProductsContainer>
        <ProductSection>
          <ProductTitle>Offerings</ProductTitle>
          <ProductText>
            Moveflow offers a comprehensive suite of location intelligence solutions designed to help businesses make data-driven decisions. Our products provide valuable insights into demographic patterns, consumer behavior, and location-based trends.
          </ProductText>
          
          <ProductGrid>
            <ProductCard>
              <IconContainer>
                <MapIcon />
              </IconContainer>
              <ProductSubtitle>Interactive Mapping</ProductSubtitle>
              <ProductText>
                Our interactive mapping platform allows you to visualize demographic data across different geographic levels, from census blocks to states. Explore population characteristics, income levels, education, and more through an intuitive interface.
              </ProductText>
            </ProductCard>
            
            <ProductCard>
              <IconContainer>
                <AnalyticsIcon />
              </IconContainer>
              <ProductSubtitle>Predictive Analytics</ProductSubtitle>
              <ProductText>
                Leverage our advanced predictive models to forecast future location-based trends. Our analytics tools help you identify emerging markets, optimize site selection, and stay ahead of demographic shifts that could impact your business.
              </ProductText>
            </ProductCard>
            
            <ProductCard>
              <IconContainer>
                <DemographicIcon />
              </IconContainer>
              <ProductSubtitle>Demographic Insights</ProductSubtitle>
              <ProductText>
                Access comprehensive demographic profiles for any location in the United States. Our platform provides detailed information on population characteristics, household composition, income distribution, and consumer behavior patterns.
              </ProductText>
            </ProductCard>
            
            <ProductCard>
              <IconContainer>
                <ApiIcon />
              </IconContainer>
              <ProductSubtitle>Data Integration</ProductSubtitle>
              <ProductText>
                Seamlessly integrate our location intelligence with your existing systems. Our solutions can be incorporated into your business workflows, CRM systems, and decision-making processes to enhance operational efficiency.
              </ProductText>
            </ProductCard>
          </ProductGrid>
        </ProductSection>
        
        <ApiSection>
          <ApiHeader>
            <CodeIcon />
            <ApiTitle>API Access</ApiTitle>
          </ApiHeader>
          <ProductText>
            Access our powerful location intelligence programmatically through our comprehensive API. Integrate demographic data, mapping capabilities, and predictive analytics directly into your applications and systems.
          </ProductText>
          
          <ApiFeatureGrid>
            <ApiFeature>
              <ApiFeatureIcon>
                <CheckIcon />
              </ApiFeatureIcon>
              <ApiFeatureText>
                <ApiFeatureTitle>RESTful Endpoints</ApiFeatureTitle>
                <ApiFeatureDescription>
                  Our well-documented RESTful API provides easy access to all our data products and services.
                </ApiFeatureDescription>
              </ApiFeatureText>
            </ApiFeature>
            
            <ApiFeature>
              <ApiFeatureIcon>
                <CheckIcon />
              </ApiFeatureIcon>
              <ApiFeatureText>
                <ApiFeatureTitle>Real-time Data</ApiFeatureTitle>
                <ApiFeatureDescription>
                  Get up-to-date demographic information and location intelligence whenever you need it.
                </ApiFeatureDescription>
              </ApiFeatureText>
            </ApiFeature>
            
            <ApiFeature>
              <ApiFeatureIcon>
                <CheckIcon />
              </ApiFeatureIcon>
              <ApiFeatureText>
                <ApiFeatureTitle>Flexible Integration</ApiFeatureTitle>
                <ApiFeatureDescription>
                  Easily integrate with your existing systems, whether you're using web applications, mobile apps, or data analytics platforms.
                </ApiFeatureDescription>
              </ApiFeatureText>
            </ApiFeature>
            
            <ApiFeature>
              <ApiFeatureIcon>
                <CheckIcon />
              </ApiFeatureIcon>
              <ApiFeatureText>
                <ApiFeatureTitle>Custom Data Queries</ApiFeatureTitle>
                <ApiFeatureDescription>
                  Build custom queries to extract exactly the data you need for your specific business requirements.
                </ApiFeatureDescription>
              </ApiFeatureText>
            </ApiFeature>
            
            <ApiFeature>
              <ApiFeatureIcon>
                <CheckIcon />
              </ApiFeatureIcon>
              <ApiFeatureText>
                <ApiFeatureTitle>Secure Authentication</ApiFeatureTitle>
                <ApiFeatureDescription>
                  Industry-standard authentication and authorization protocols ensure your data remains secure.
                </ApiFeatureDescription>
              </ApiFeatureText>
            </ApiFeature>
            
            <ApiFeature>
              <ApiFeatureIcon>
                <CheckIcon />
              </ApiFeatureIcon>
              <ApiFeatureText>
                <ApiFeatureTitle>Developer Support</ApiFeatureTitle>
                <ApiFeatureDescription>
                  Comprehensive documentation, code samples, and dedicated support to help you implement our API successfully.
                </ApiFeatureDescription>
              </ApiFeatureText>
            </ApiFeature>
          </ApiFeatureGrid>
        </ApiSection>
      </ProductsContainer>
      <Footer />
    </ProductsPageWrapper>
  );
};

export default Products; 