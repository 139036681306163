import React from 'react';
import styled from 'styled-components';
import theme from '../theme';
import mapIcon from '../images/map-icon.svg';
import reportIcon from '../images/report-icon.svg';

const TechContainer = styled.section`
  width: 100%;
  box-sizing: border-box;
  background-color: ${theme.colors.background};
  padding: ${theme.space[5]} 0;
`;

const TechContent = styled.div`
  max-width: ${theme.sizes.container};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${theme.space[4]};
`;

const TechHeader = styled.div`
  text-align: center;
  margin-bottom: ${theme.space[5]};
`;

const TechKicker = styled.p`
  font-size: ${theme.typography.fontSizes.sm};
  text-transform: uppercase;
  letter-spacing: ${theme.typography.letterSpacings.wide};
  color: ${theme.colors.text};
  font-weight: ${theme.typography.fontWeights.bold};
  margin-bottom: ${theme.space[2]};
`;

const TechHeading = styled.h2`
  font-size: ${theme.typography.fontSizes['4xl']};
  color: ${theme.colors.text};
  font-weight: ${theme.typography.fontWeights.extrabold};
  line-height: ${theme.typography.lineHeights.tight};
  letter-spacing: ${theme.typography.letterSpacings.tight};
  font-family: ${theme.typography.fontFamily.secondary};
  margin-bottom: ${theme.space[3]};
`;

const TechSubhead = styled.p`
  font-size: ${theme.typography.fontSizes.lg};
  line-height: ${theme.typography.lineHeights.relaxed};
  color: ${theme.colors.text};
  font-weight: ${theme.typography.fontWeights.normal};
  max-width: 800px;
  margin: 0 auto;
`;

const TechGrid = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TechItem = styled.div`
  flex: 1;
  padding: ${theme.space[4]};
  text-align: center;
  
  @media (min-width: ${theme.breakpoints.medium}) {
    &:first-child {
      margin-right: ${theme.space[4]};
    }
    
    &:last-child {
      margin-left: ${theme.space[4]};
    }
  }
`;

const TechIconContainer = styled.div`
  margin-bottom: ${theme.space[3]};
  
  img {
    width: 80px;
    height: 80px;
  }
`;

const TechItemTitle = styled.h3`
  font-size: ${theme.typography.fontSizes['2xl']};
  color: ${theme.colors.text};
  font-weight: ${theme.typography.fontWeights.bold};
  line-height: ${theme.typography.lineHeights.tight};
  margin-bottom: ${theme.space[3]};
`;

const TechItemText = styled.p`
  font-size: ${theme.typography.fontSizes.md};
  line-height: ${theme.typography.lineHeights.relaxed};
  color: ${theme.colors.text};
  text-align: left;
`;

const Technology = ({ kicker, heading, text }) => {
  return (
    <TechContainer id="technology">
      <TechContent>
        <TechHeader>
          <TechKicker>{kicker || "TECHNOLOGY"}</TechKicker>
          <TechHeading>{heading || "The state of the art"}</TechHeading>
          <TechSubhead>
            {text || "Moveflow's proprietary AI technology transforms location data into actionable business intelligence, backed by years of research and proven results across multiple industries."}
          </TechSubhead>
        </TechHeader>
        
        <TechGrid>
          <TechItem>
            <TechIconContainer>
              <img src={mapIcon} alt="How it works" />
            </TechIconContainer>
            <TechItemTitle>How it works</TechItemTitle>
            <TechItemText>
              Our patented approach extrapolates comprehensive population movement patterns from a small, privacy-compliant sample of volunteering individuals. Using advanced mathematical modeling and machine learning, we can accurately predict movement behaviors for entire regions with remarkable precision.
            </TechItemText>
            <TechItemText>
              Moveflow combines neural networks, geospatial analytics, and temporal reasoning algorithms to create a digital twin of physical environments. This allows businesses to visualize, analyze, and predict human movement patterns with unprecedented accuracy, even in rapidly changing conditions.
            </TechItemText>
          </TechItem>
          
          <TechItem>
            <TechIconContainer>
              <img src={reportIcon} alt="The Moveflow Simulation" />
            </TechIconContainer>
            <TechItemTitle>The Moveflow Simulation</TechItemTitle>
            <TechItemText>
              Our simulation engine processes millions of data points to create a living model of human activity in any location. It continuously adapts to real-time factors including weather events, traffic patterns, local activities, and even social media trends that influence movement behavior.
            </TechItemText>
            <TechItemText>
              This dynamic digital twin enables businesses to forecast foot traffic, optimize location-based decisions, and identify emerging opportunities before competitors. Retail chains use our technology to select optimal store locations, real estate developers predict property values, and urban planners design more efficient transportation systems—all powered by Moveflow's predictive accuracy.
            </TechItemText>
          </TechItem>
        </TechGrid>
      </TechContent>
    </TechContainer>
  );
};

export default Technology; 