import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import theme from '../theme';
import Layout from '../components/Layout';
import DemographicPanel from '../components/DemographicPanel';
import { LoadingComponent } from '../components';
import SEO from '../components/SEO';
import StructuredData from '../components/StructuredData';
import {
  fetchDemographicsByRegion,
  fetchPopulationData,
  convertToCSV,
  downloadCSV,
  preloadStateData
} from '../services/demographicService';

// Import Leaflet and its CSS
import 'leaflet/dist/leaflet.css';
import '../styles/MapStyles.css';

// Import Leaflet library
import L from 'leaflet';

// Fix Leaflet icon issues
import icon from 'leaflet/dist/images/marker-icon.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// Fix default icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});

// Define zoom level constants
// Desktop zoom levels
const DESKTOP_ZOOM_LEVEL_STATE = {min: 2, max: 7};
const DESKTOP_ZOOM_LEVEL_PUMA = {min: 8, max: 13};
const DESKTOP_ZOOM_LEVEL_BLOCK_GROUPS = {min: 14, max: 16};
const DESKTOP_ZOOM_LEVEL_BLOCKS = {min: 17, max: Infinity};

// Mobile zoom levels (adjusted to show more detail at lower zoom levels)
const MOBILE_ZOOM_LEVEL_STATE = {min: 2, max: 5}; // Show states at lower zoom
const MOBILE_ZOOM_LEVEL_PUMA = {min: 6, max: 11}; // Show PUMAs earlier
const MOBILE_ZOOM_LEVEL_BLOCK_GROUPS = {min: 12, max: 14}; // Show block groups earlier
const MOBILE_ZOOM_LEVEL_BLOCKS = {min: 15, max: Infinity}; // Show blocks earlier

// Function to determine if we're on mobile
const isMobileDevice = () => {
  return typeof window !== 'undefined' && window.innerWidth <= 768;
};

// Set the appropriate zoom levels based on device type
const ZOOM_LEVEL_STATE = isMobileDevice() ? MOBILE_ZOOM_LEVEL_STATE : DESKTOP_ZOOM_LEVEL_STATE;
const ZOOM_LEVEL_PUMA = isMobileDevice() ? MOBILE_ZOOM_LEVEL_PUMA : DESKTOP_ZOOM_LEVEL_PUMA;
const ZOOM_LEVEL_BLOCK_GROUPS = isMobileDevice() ? MOBILE_ZOOM_LEVEL_BLOCK_GROUPS : DESKTOP_ZOOM_LEVEL_BLOCK_GROUPS;
const ZOOM_LEVEL_BLOCKS = isMobileDevice() ? MOBILE_ZOOM_LEVEL_BLOCKS : DESKTOP_ZOOM_LEVEL_BLOCKS;

// Main container for the entire demo page
const DemoContainer = styled.div`
  width: 100%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.background};
  
  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

// Fancy map container with shadow and rounded corners
const MapWrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  height: 80vh;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 20px 0;
  
  @media (max-width: 768px) {
    width: 95%;
    height: 80vh;
    border-radius: 15px;
    margin: 10px 0;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, ${theme.colors.primary}, ${theme.colors.secondary});
    z-index: 1001;
  }
`;

// Container for the map itself
const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  
  @media (max-width: 768px) {
    border-radius: 15px;
  }
`;

// Title for the map section
const MapTitle = styled.h2`
  font-size: 28px;
  color: ${theme.colors.text};
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
  
  @media (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 5px;
  }
`;

// Description text below the title
const MapDescription = styled.p`
  font-size: 16px;
  color: ${theme.colors.textSecondary};
  margin-bottom: 30px;
  text-align: center;
  max-width: 700px;
  
  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 15px;
    padding: 0 10px;
  }
`;

const InfoBanner = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background: rgba(255, 255, 255, 0.9);
  padding: 12px 24px;
  border-radius: 20px;
  box-shadow: ${theme.shadows.md};
  font-size: 14px;
  display: ${props => props.visible ? 'block' : 'none'};
  border: 1px solid rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  transition: ${theme.transitions.default};
  
  @media (max-width: 768px) {
    bottom: 10px;
    padding: 6px 12px;
    font-size: 10px;
    border-radius: 12px;
    width: auto;
    max-width: 80%;
    text-align: center;
  }
  
  strong {
    color: ${theme.colors.primary};
    font-weight: ${theme.typography.fontWeights.semibold};
  }
  
  &:hover {
    box-shadow: ${theme.shadows.lg};
    transform: translateX(-50%) translateY(-2px);
    
    @media (max-width: 768px) {
      transform: translateX(-50%);
    }
  }
`;

// Panel container for demographic panel
const PanelOverlay = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  display: ${props => props.visible ? 'block' : 'none'};
  
  @media (max-width: 768px) {
    top: 10px;
    right: 10px;
  }
`;

// Add zoom controls styling
const ZoomControl = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) {
    bottom: 70px;
    left: 10px;
  }
`;

const ZoomButton = styled.button`
  width: 36px;
  height: 36px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
    font-size: 14px;
    margin-bottom: 3px;
  }
  
  &:hover {
    background: #f8f8f8;
  }
`;

const Demo = () => {
  const [zoomLevel, setZoomLevel] = useState(5);
  const [map, setMap] = useState(null);
  
  // State variables for demographics
  const [isLoading, setIsLoading] = useState(false);
  const [demographicData, setDemographicData] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [lastLoadedBounds, setLastLoadedBounds] = useState(null);
  const [currentLevelOfDetail, setCurrentLevelOfDetail] = useState('None');
  
  // Reference to the GeoJSON layer
  const geoJsonLayerRef = useRef(null);
  
  // Loading message state
  const [loadingMessage, setLoadingMessage] = useState('Decoding market intelligence...');
  
  // Initialize the map
  useEffect(() => {
    if (map) return; // Map already initialized
    
    // Create map with the same approach as the HTML/JS version
    const leafletMap = L.map('map', {
      zoomControl: false
    });
    
    // Add Google Maps tile layer
    L.tileLayer('https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga', {
      maxZoom: 18,
      minZoom: 4,
      attribution: 'Map data and imagery &copy; <a href="https://www.google.com/maps">Google Maps</a>, ' +
        'Mobility data &copy; <a href="https://moveflow.ai">Moveflow</a>'
    }).addTo(leafletMap);
    
    // Set USA bounds
    const southWest = L.latLng(24.396308, -125.000000);
    const northEast = L.latLng(49.384358, -66.934570);
    const bounds = L.latLngBounds(southWest, northEast);
    leafletMap.fitBounds(bounds);
    
    // Set up zoom level change handler
    leafletMap.on('zoomend', () => {
      setZoomLevel(leafletMap.getZoom());
    });
    
    // Add debug function to window for console access
    if (typeof window !== 'undefined') {
      window.debugMap = {
        showLoadedBounds: () => {
          if (!lastLoadedBounds) {
            console.log('No loaded bounds to show');
            return;
          }
          
          // Remove existing rectangle if any
          if (window.debugBoundsRect) {
            leafletMap.removeLayer(window.debugBoundsRect);
          }
          
          // Add a rectangle showing the loaded bounds
          window.debugBoundsRect = L.rectangle(lastLoadedBounds, {
            color: '#ff7800',
            weight: 2,
            fillOpacity: 0.1,
            dashArray: '5, 10'
          }).addTo(leafletMap);
          
          console.log('Showing loaded bounds rectangle');
          return 'Loaded bounds visualized on map';
        },
        
        clearDebugLayers: () => {
          if (window.debugBoundsRect) {
            leafletMap.removeLayer(window.debugBoundsRect);
            window.debugBoundsRect = null;
          }
          console.log('Cleared debug layers');
          return 'Debug layers cleared';
        }
      };
      
      // Debug helper: To enable cache notifications, run in console:
      // window.toggleDebugNotifications(true)
    }
    
    // Store the map instance
    setMap(leafletMap);
    
    // Preload state-level data
    console.log('Preloading state-level data...');
    preloadStateData().then(() => {
      console.log('State data preloaded successfully');
    }).catch(error => {
      console.error('Failed to preload state data:', error);
    });
    
    // Clean up on unmount
    return () => {
      if (leafletMap) {
        leafletMap.remove();
      }
    };
  }, []);
  
  // Helper function to get buffered bounds to prevent too frequent data loading
  const getBufferedBounds = (bounds) => {
    if (!bounds) return null;
    
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    
    // Calculate buffer sizes based on the current level of detail
    // Use larger buffers for higher zoom levels to make panning smoother
    let bufferPercentage;
    const currentZoom = map.getZoom();
    const levelOfDetail = getCurrentLevelOfDetail(currentZoom);
    
    switch (levelOfDetail) {
      case 'States':
        bufferPercentage = 0.1; // 10% buffer for states (they're large)
        break;
      case 'PUMA':
        bufferPercentage = 0.5; // 50% buffer for PUMAs to make panning smoother
        break;
      case 'Block Groups':
        bufferPercentage = 0.3; // 30% buffer for Block Groups
        break;
      case 'Blocks':
        bufferPercentage = 0.2; // 20% buffer for Blocks (they're small and numerous)
        break;
      default:
        bufferPercentage = 0.25; // 25% default buffer
    }
    
    console.log(`Using ${bufferPercentage * 100}% buffer for ${levelOfDetail} level`);
    
    const latBuffer = Math.abs(ne.lat - sw.lat) * bufferPercentage;
    const lngBuffer = Math.abs(ne.lng - sw.lng) * bufferPercentage;
    
    // Create new bounds with the buffer
    return L.latLngBounds(
      [sw.lat - latBuffer, sw.lng - lngBuffer],
      [ne.lat + latBuffer, ne.lng + lngBuffer]
    );
  };
  
  // Helper function to convert map bounds to polygon coordinates
  const getCurrentViewBoundingBoxPolygon = (bounds) => {
    if (!bounds) return [];
    
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    
    // Creating the polygon coordinates
    return [
      [southWest.lat, southWest.lng], // bottom-left
      [southWest.lat, northEast.lng], // bottom-right
      [northEast.lat, northEast.lng], // top-right
      [northEast.lat, southWest.lng], // top-left
      [southWest.lat, southWest.lng]  // bottom-left again to close the polygon
    ];
  };
  
  // Function to determine current level of detail based on zoom
  const getCurrentLevelOfDetail = (zoom) => {
    if (!zoom) return 'None';
    
    console.log(`Determining level of detail for zoom level: ${zoom}`);
    
    let levelOfDetail;
    if (zoom <= ZOOM_LEVEL_STATE.max) {
      console.log(`Zoom level ${zoom} is within state range (${ZOOM_LEVEL_STATE.min}-${ZOOM_LEVEL_STATE.max})`);
      levelOfDetail = 'States';
    } else if (zoom >= ZOOM_LEVEL_PUMA.min && zoom <= ZOOM_LEVEL_PUMA.max) {
      levelOfDetail = 'PUMA';
    } else if (zoom >= ZOOM_LEVEL_BLOCK_GROUPS.min && zoom <= ZOOM_LEVEL_BLOCK_GROUPS.max) {
      levelOfDetail = 'Block Groups';
    } else if (zoom >= ZOOM_LEVEL_BLOCKS.min) {
      levelOfDetail = 'Blocks';
    } else {
      levelOfDetail = 'None';
    }
    
    // Expose the current level of detail to the window object for caching
    window.currentLevelOfDetail = levelOfDetail;
    
    return levelOfDetail;
  };
  
  // Update current level of detail when zoom level changes
  useEffect(() => {
    if (!map) return;
    
    const newLevelOfDetail = getCurrentLevelOfDetail(zoomLevel);
    setCurrentLevelOfDetail(newLevelOfDetail);
    
    // Log the current level of detail for debugging
    console.log(`Current level of detail set to: ${newLevelOfDetail}`);
  }, [zoomLevel, map]);
  
  // Function to determine if data should be reloaded on map movement
  const shouldLoadDataOnMove = () => {
    if (!lastLoadedBounds) return true; // Always load if no bounds are set
    
    const currentBounds = map.getBounds();
    
    // Calculate the overlap percentage between current bounds and last loaded bounds
    const currentArea = (currentBounds.getNorth() - currentBounds.getSouth()) * 
                        (currentBounds.getEast() - currentBounds.getWest());
    
    // Get the intersection of the two bounds
    const southIntersect = Math.max(currentBounds.getSouth(), lastLoadedBounds.getSouth());
    const northIntersect = Math.min(currentBounds.getNorth(), lastLoadedBounds.getNorth());
    const westIntersect = Math.max(currentBounds.getWest(), lastLoadedBounds.getWest());
    const eastIntersect = Math.min(currentBounds.getEast(), lastLoadedBounds.getEast());
    
    // Check if there's any intersection
    if (southIntersect > northIntersect || westIntersect > eastIntersect) {
      // No intersection, definitely reload
      console.log('No intersection with previous bounds, reloading data');
      return true;
    }
    
    // Calculate intersection area
    const intersectionArea = (northIntersect - southIntersect) * (eastIntersect - westIntersect);
    
    // Calculate what percentage of the current view is covered by previously loaded data
    const overlapPercentage = intersectionArea / currentArea;
    console.log(`Overlap with previous bounds: ${(overlapPercentage * 100).toFixed(1)}%`);
    
    // If less than 70% of the current view is covered by previously loaded data, reload
    // This ensures new data is loaded before the user reaches the edge of the loaded area
    if (overlapPercentage < 0.7) {
      console.log('Overlap below threshold, reloading data');
      return true;
    }
    
    return false;
  };
  
  // Function to determine if data should be reloaded on zoom change
  const shouldLoadDataOnZoom = (currentZoom) => {
    const newLevelOfDetail = getCurrentLevelOfDetail(currentZoom);
    return newLevelOfDetail && newLevelOfDetail !== currentLevelOfDetail;
  };
  
  // Styling for demographic layers
  const demographicStyle = (feature) => {
    // Check if quota exceeded
    if ('quota' in feature.properties && feature.properties.quota === 'exceeded') {
      return {
        color: '#4a76a8',
        weight: 3,
        opacity: 0.8,
        fillOpacity: 0.3,
        fillColor: '#ff0000',
        smoothFactor: 1.0
      };
    }
    
    // Get the current level of detail
    const levelOfDetail = getCurrentLevelOfDetail(map.getZoom());
    console.log(`Styling feature for level: ${levelOfDetail}`, feature.properties.name || 'unnamed');
    
    // Different styling based on level of detail
    if (levelOfDetail === 'States') {
      return {
        color: '#2c3e50',  // Darker border for states
        weight: 3,
        opacity: 0.9,
        fillOpacity: 0.2,
        fillColor: '#3498db',  // Blue fill for states
        smoothFactor: 1.0
      };
    } else if (levelOfDetail === 'PUMA') {
      return {
        color: '#27ae60',  // Green for PUMAs
        weight: 2,
        opacity: 0.8,
        fillOpacity: 0.3,
        fillColor: '#2ecc71',
        smoothFactor: 1.0
      };
    } else if (levelOfDetail === 'Block Groups') {
      return {
        color: '#8e44ad',  // Purple for Block Groups
        weight: 2,
        opacity: 0.8,
        fillOpacity: 0.3,
        fillColor: '#9b59b6',
        smoothFactor: 1.0
      };
    } else if (levelOfDetail === 'Blocks') {
      return {
        color: '#d35400',  // Orange for Blocks
        weight: 1,
        opacity: 0.8,
        fillOpacity: 0.3,
        fillColor: '#e67e22',
        smoothFactor: 1.0
      };
    }
    
    // Default style
    return {
      color: '#4a76a8',
      weight: 3,
      opacity: 0.8,
      fillOpacity: 0.3,
      fillColor: '#4a76a8',
      smoothFactor: 1.0
    };
  };
  
  // Feature interaction functions
  const highlightFeature = (e) => {
    const layer = e.target;
    
    if (selectedFeature !== layer) {
      layer.setStyle({
        weight: 5,
        color: '#5e8cc0',
        fillOpacity: 0.5
      });
    }
  };
  
  const resetHighlight = (e) => {
    const layer = e.target;
    
    if (selectedFeature !== layer && geoJsonLayerRef.current) {
      geoJsonLayerRef.current.resetStyle(layer);
    }
  };
  
  const selectFeature = (e) => {
    const layer = e.target;
    
    // Reset previous selection if exists
    if (selectedFeature && geoJsonLayerRef.current) {
      geoJsonLayerRef.current.resetStyle(selectedFeature);
    }
    
    // Set new selection
    setSelectedFeature(layer);
    
    layer.setStyle({
      weight: 5,
      color: '#3e5d7e',
      fillOpacity: 0
    });
    
    // Bring to front
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    
    // Check for quota exceeded
    if ('quota' in layer.feature.properties && layer.feature.properties.quota === 'exceeded') {
      alert("Sorry, you have exceeded your daily quota for this resolution. To access more data today, please consider upgrading your plan. Otherwise, you can try again tomorrow.");
      return;
    }
  };
  
  // Set up feature interaction
  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: selectFeature
    });
  };
  
  // Function to load demographic data with debouncing
  const [loadDataTimeout, setLoadDataTimeout] = useState(null);
  
  const loadDemographicData = async (immediate = false) => {
    if (!map) return;
    
    // Clear any existing timeout
    if (loadDataTimeout) {
      clearTimeout(loadDataTimeout);
    }
    
    // If not immediate, debounce the loading
    if (!immediate) {
      setLoadDataTimeout(setTimeout(() => {
        loadDemographicDataImpl();
      }, 300)); // 300ms debounce
    } else {
      loadDemographicDataImpl();
    }
  };
  
  const loadDemographicDataImpl = async () => {
    setIsLoading(true);
    try {
      // Get buffered bounds to prevent too frequent reloading
      const bounds = getBufferedBounds(map.getBounds());
      setLastLoadedBounds(bounds);
      
      const polygon = getCurrentViewBoundingBoxPolygon(bounds);
      const zoomLevelDetail = getCurrentLevelOfDetail(map.getZoom());
      
      console.log(`Loading demographic data for level: ${zoomLevelDetail}`);
      
      if (zoomLevelDetail === 'None') {
        setDemographicData(null);
        setSelectedFeature(null);
        return;
      }
      
      // Store previous level of detail to compare
      const previousLevelOfDetail = currentLevelOfDetail;
      
      // Update loading message based on the current level of detail
      if (zoomLevelDetail === 'States') {
        setLoadingMessage(`Analyzing state-level markets...`);
      } else if (zoomLevelDetail === 'PUMAs') {
        setLoadingMessage(`Uncovering regional opportunities...`);
      } else if (zoomLevelDetail === 'Block Groups') {
        setLoadingMessage(`Revealing neighborhood insights...`);
      } else if (zoomLevelDetail === 'Blocks') {
        setLoadingMessage(`Extracting hyper-local intelligence...`);
      }
      
      // Fetch demographic data
      const data = await fetchDemographicsByRegion(zoomLevelDetail, polygon);
      console.log(`Received demographic data:`, data);
      setDemographicData(data);
      
      // Clear selected feature when loading new data
      setSelectedFeature(null);
      
      // Remove existing GeoJSON layer if it exists
      if (geoJsonLayerRef.current) {
        map.removeLayer(geoJsonLayerRef.current);
      }
      
      // Add new GeoJSON layer
      if (data && data.features && data.features.length > 0) {
        console.log(`Adding GeoJSON layer with ${data.features.length} features`);
        const geoJsonLayer = L.geoJSON(data, {
          style: demographicStyle,
          onEachFeature: onEachFeature
        }).addTo(map);
        
        geoJsonLayerRef.current = geoJsonLayer;
      } else {
        console.warn(`No features found in demographic data for level: ${zoomLevelDetail}`);
      }
    } catch (error) {
      console.error('Failed to load demographic data:', error);
      setLoadingMessage('Error loading data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Set up map event handlers when map is ready
  useEffect(() => {
    if (!map) return;
    
    // Load demographic data immediately when map is ready
    loadDemographicData(true);
    
    // Set up map event handlers
    const handleMapMove = () => {
      if (shouldLoadDataOnMove()) {
        loadDemographicData();
      }
    };
    
    const handleMapZoom = () => {
      if (shouldLoadDataOnZoom(map.getZoom())) {
        loadDemographicData(true); // Load immediately on zoom change
      }
    };
    
    map.on('moveend', handleMapMove);
    map.on('zoomend', handleMapZoom);
    
    // Cleanup event handlers
    return () => {
      map.off('moveend', handleMapMove);
      map.off('zoomend', handleMapZoom);
      
      // Clear any pending load timeout
      if (loadDataTimeout) {
        clearTimeout(loadDataTimeout);
      }
    };
  }, [map]);
  
  // Handle population data download
  const handleDownloadData = async () => {
    if (!selectedFeature) return;
    
    setIsLoading(true);
    setLoadingMessage('Preparing data for download...');
    try {
      const feature = selectedFeature.feature;
      let params = {};
      
      // Determine the appropriate ID to send based on zoom level
      if (currentLevelOfDetail === 'PUMA') {
        params = {
          zoom_level: 'PUMA',
          id: feature.properties.PUMA_GEOID
        };
      } else if (currentLevelOfDetail === 'Block Groups') {
        params = {
          zoom_level: 'block_groups',
          id: feature.properties.BG_GEOID
        };
      } else if (currentLevelOfDetail === 'Blocks') {
        params = {
          zoom_level: 'block',
          id: feature.properties.GEOID20
        };
      } else {
        alert('Population data download is not available for this zoom level');
        return;
      }
      
      // Fetch population data
      const data = await fetchPopulationData(params);
      
      // Convert to CSV and download
      const csvData = convertToCSV(data);
      downloadCSV(csvData, 'population_data.csv');
    } catch (error) {
      console.error('Failed to download population data:', error);
      alert('Failed to download data: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Get current level of detail for info banner
  const showInfoBanner = currentLevelOfDetail !== 'None';
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;
  
  // Handler to close the demographic panel
  const handleCloseDemographicPanel = () => {
    setSelectedFeature(null);
  };

  return (
    <>
      <SEO 
        title="Interactive Market Intelligence Map"
        description="Visualize population dynamics in real time. Discover untapped markets, consumer behavior patterns, and demographic shifts that create competitive opportunities for your business."
        keywords="market intelligence map, interactive demographics, business opportunities, consumer behavior, population trends, competitive intelligence, location strategy, market analysis"
        path="/map"
      />
      <StructuredData type="SoftwareApplication" />
      <Layout>
        <DemoContainer>
          <MapTitle>Discover Untapped Market Potential</MapTitle>
          <MapDescription>
            Navigate America's demographic landscape to reveal business opportunities. 
            Each zoom level uncovers new insights—click any region to unlock detailed market intelligence.
          </MapDescription>
          
          <MapWrapper>
            <MapContainer id="map"></MapContainer>
            
            {/* Loading indicator with improved visibility and positioning */}
            <LoadingComponent 
              isVisible={isLoading} 
              message={loadingMessage}
            />
            
            {/* Demographic info panel */}
            <PanelOverlay visible={selectedFeature !== null}>
              {selectedFeature && (
                <DemographicPanel 
                  properties={selectedFeature.feature.properties}
                  isLoading={isLoading}
                  onDownload={handleDownloadData}
                  onClose={handleCloseDemographicPanel}
                />
              )}
            </PanelOverlay>
            
            <InfoBanner visible={showInfoBanner && selectedFeature === null}>
              {isMobile ? (
                <>
                  <strong>{currentLevelOfDetail === 'States' ? 'State Markets' : 
                           currentLevelOfDetail === 'PUMA' ? 'Regional Markets' : 
                           currentLevelOfDetail === 'Block Groups' ? 'Neighborhood Markets' : 
                           'Local Markets'}</strong>
                  {currentLevelOfDetail === 'States' && ' (Zoom for regional detail)'}
                  {currentLevelOfDetail === 'PUMA' && ' (Zoom for neighborhood detail)'}
                  {currentLevelOfDetail === 'Block Groups' && ' (Zoom for hyper-local detail)'}
                  {currentLevelOfDetail === 'Blocks' && ' (Zoom out for broader view)'}
                </>
              ) : (
                <>
                  {currentLevelOfDetail === 'States' && 'Viewing State-Level Markets — Zoom in to discover regional opportunities'}
                  {currentLevelOfDetail === 'PUMA' && 'Exploring Regional Markets — Zoom in to reveal neighborhood dynamics'}
                  {currentLevelOfDetail === 'Block Groups' && 'Analyzing Neighborhood Markets — Zoom in for hyper-local insights'}
                  {currentLevelOfDetail === 'Blocks' && 'Examining Local Markets — Zoom out to see broader patterns'}
                </>
              )}
            </InfoBanner>
          </MapWrapper>
        </DemoContainer>
      </Layout>
    </>
  );
};

export default Demo; 