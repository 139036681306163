import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';
import styled from 'styled-components';
import theme from '../theme';

const ChartContainer = styled.div`
  margin-bottom: 15px;
  height: 120px;
  
  @media (max-width: 768px) {
    height: 70px;
    margin-bottom: 8px;
  }
`;

/**
 * Creates a bar chart for age distribution data
 */
export const AgeDistributionChart = ({ ageGroups }) => {
  const chartRef = useRef(null);
  
  useEffect(() => {
    if (!chartRef.current || !ageGroups || !ageGroups.length) return;
    
    const ctx = chartRef.current.getContext('2d');
    const isMobile = window.innerWidth <= 768;
    
    // Destroy existing chart if it exists
    if (Chart.getChart(ctx)) {
      Chart.getChart(ctx).destroy();
    }
    
    // Modify labels for mobile
    const labels = ageGroups.map(group => {
      if (isMobile) {
        // Replace "years" with "y" on mobile and simplify further
        return group.group.replace('years', '').replace('-', '-');
      }
      return group.group;
    });
    
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          data: ageGroups.map(group => group.count),
          backgroundColor: theme.colors.primary || 'rgba(88,74,197, 1)',
          borderColor: 'rgba(0,0,0, 1)',
          borderWidth: isMobile ? 0 : 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: !isMobile
          }
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: isMobile ? 8 : 12
              },
              maxRotation: isMobile ? 0 : 0,
              autoSkip: true,
              autoSkipPadding: isMobile ? 5 : 10
            },
            grid: {
              display: !isMobile
            }
          },
          y: {
            ticks: {
              font: {
                size: isMobile ? 8 : 12
              },
              maxTicksLimit: isMobile ? 3 : 6
            },
            grid: {
              display: !isMobile
            }
          }
        }
      }
    });
  }, [ageGroups]);
  
  return (
    <ChartContainer>
      <canvas ref={chartRef} />
    </ChartContainer>
  );
};

/**
 * Creates a bar chart for income distribution data
 */
export const IncomeDistributionChart = ({ incomeHistogram }) => {
  const chartRef = useRef(null);
  
  useEffect(() => {
    if (!chartRef.current || !incomeHistogram || !incomeHistogram.length) return;
    
    const ctx = chartRef.current.getContext('2d');
    const isMobile = window.innerWidth <= 768;
    
    // Destroy existing chart if it exists
    if (Chart.getChart(ctx)) {
      Chart.getChart(ctx).destroy();
    }
    
    // Create income histogram bins
    const bins = [0, 20000, 40000, 60000, 80000, 100000, Infinity];
    // Use shorter labels on mobile
    const labels = isMobile 
      ? ["0-20", "20-40", "40-60", "60-80", "80-100", "100+"]
      : ["0-20k", "20k-40k", "40k-60k", "60k-80k", "80k-100k", "100k+"];
    
    // Count incomes in each bin
    let counts = new Array(bins.length - 1).fill(0);
    for (let income of incomeHistogram) {
      for (let i = 1; i < bins.length; i++) {
        if (income <= bins[i]) {
          counts[i - 1]++;
          break;
        }
      }
    }
    
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          data: counts,
          backgroundColor: theme.colors.secondary || 'rgba(88,74,197, 1)',
          borderColor: 'rgba(0,0,0, 1)',
          borderWidth: isMobile ? 0 : 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: !isMobile
          }
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: isMobile ? 8 : 12
              },
              maxRotation: isMobile ? 0 : 0
            },
            grid: {
              display: !isMobile
            }
          },
          y: {
            ticks: {
              font: {
                size: isMobile ? 8 : 12
              },
              maxTicksLimit: isMobile ? 3 : 6
            },
            grid: {
              display: !isMobile
            }
          }
        }
      }
    });
  }, [incomeHistogram]);
  
  return (
    <ChartContainer>
      <canvas ref={chartRef} />
    </ChartContainer>
  );
};

/**
 * Creates a bar chart for student school levels data
 */
export const SchoolLevelsChart = ({ schoolLevels }) => {
  const chartRef = useRef(null);
  
  useEffect(() => {
    if (!chartRef.current || !schoolLevels || !schoolLevels.length) return;
    
    const ctx = chartRef.current.getContext('2d');
    const isMobile = window.innerWidth <= 768;
    
    // Destroy existing chart if it exists
    if (Chart.getChart(ctx)) {
      Chart.getChart(ctx).destroy();
    }
    
    // Modify labels for mobile
    const labels = schoolLevels.map(level => {
      if (isMobile) {
        // Abbreviate school types for mobile
        return level.group
          .replace('Elementary', 'Elem')
          .replace('Middle', 'Mid')
          .replace('School', '')
          .replace('Pre-school', 'Pre');
      }
      return level.group;
    });
    
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          data: schoolLevels.map(level => level.count),
          backgroundColor: theme.colors.active || 'rgba(88,74,197, 1)',
          borderColor: 'rgba(0,0,0, 1)',
          borderWidth: isMobile ? 0 : 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: !isMobile
          }
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: isMobile ? 8 : 12
              },
              maxRotation: isMobile ? 0 : 0
            },
            grid: {
              display: !isMobile
            }
          },
          y: {
            ticks: {
              font: {
                size: isMobile ? 8 : 12
              },
              maxTicksLimit: isMobile ? 3 : 6
            },
            grid: {
              display: !isMobile
            }
          }
        }
      }
    });
  }, [schoolLevels]);
  
  return (
    <ChartContainer>
      <canvas ref={chartRef} />
    </ChartContainer>
  );
}; 