import React, { useEffect } from 'react';
import { Layout } from '../components';
import { LandingData } from '../content/mainContent';
import { logAnalyticsEvent } from '../utils/firebase';
import * as sections from '../components/Sections';
import SEO from '../components/SEO';
import StructuredData from '../components/StructuredData';

const Home = () => {
  useEffect(() => {
    // Log page view event
    logAnalyticsEvent('page_view');
  }, []);

  const { homepage } = LandingData.data;

  return (
    <>
      <SEO 
        title="See Tomorrow's Markets Today"
        description="Decode population movements in real time. Moveflow transforms demographic data into business intelligence that reveals market opportunities invisible to your competitors."
        keywords="population intelligence, market analysis, demographic shifts, business strategy, competitive edge, location analytics, customer behavior, market opportunities"
        path="/"
      />
      <StructuredData type="Organization" />
      <Layout>
        {homepage.blocks.map(block => {
          const { blocktype } = block;

          const Component = sections[blocktype];
          if (!Component) {
            return null;
          }
          
          return <Component key={block.id} {...block} />;
        })}
      </Layout>
    </>
  );
};

export default Home; 