export const LandingData = {
  "data": {
    "homepage": {
      "id": "j65234",
      "title": "Moveflow",
      "description": "Moveflow transforms raw population data into strategic business intelligence, giving decision-makers the demographic insights they need to outpace competitors in an evolving market.",
      "blocks": [
        {
          "id": "a34722",
          "blocktype": "HomepageHero",
          "kicker": "POPULATION INTELLIGENCE THAT DRIVES RESULTS",
          "subhead": "Traditional data sources are stuck in the past. Moveflow isn't. " +
            "Our AI engine decodes population movements and demographic shifts in real time, revealing market opportunities invisible to your competitors. " +
            "While others rely on outdated census figures, you'll make decisions based on what's happening right now. " +
            "Turn demographic insights into your competitive edge.",
          "h1": "See Tomorrow's Markets Today",
          "links": [
            {
              "id": "a34723",
              "href": "/map",
              "text": "Discover Hidden Opportunities",
            }
          ]
        },
        {
          "id": "loc123",
          "blocktype": "LocationIntelligence",
          "heading": "Location Intelligence That Reveals What Others Miss",
          "text": "Where traditional analytics sees only statistics, our platform reveals the human stories and market opportunities hiding in plain sight. Spot emerging trends before they become obvious to your competition."
        },
        {
          "id": "tech456",
          "blocktype": "HomepageTechnology",
          "kicker": "TECHNOLOGY",
          "heading": "AI-Powered Market Revelation",
          "text": "Our algorithms don't just process data—they decode human behavior. By analyzing movement patterns, spending habits, and demographic evolution, we transform complex population dynamics into clear, actionable business strategies."
        },
        {
          "id": "c54782",
          "blocktype": "HomepageCta",
          "kicker": "Your Move",
          "heading": "Stop Guessing. Start Knowing.",
          "text": "The difference between a successful business decision and a costly mistake often comes down to having the right insights at the right time. Let's discuss how population intelligence can transform your approach to markets, locations, and customer acquisition.",
          "links": [
            {
              "id": "c51ahh",
              "href": "mailto:info@moveflow.ai",
              "text": "Unlock Strategic Insights"
            }
          ]
        }
      ]
    }
  }
}; 