import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyA3o1QyLizaL4ax4Ma9Pp61R3_AEXkMwv8",
  authDomain: "zeta-rush-295408.firebaseapp.com",
  projectId: "zeta-rush-295408",
  storageBucket: "zeta-rush-295408.appspot.com",
  messagingSenderId: "234701837136",
  appId: "1:234701837136:web:eabfdc10595e4a1eddd255",
  measurementId: "G-J3BX5NLQER"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Helper function to log events
export const logAnalyticsEvent = (eventName, eventParams = {}) => {
  logEvent(analytics, eventName, eventParams);
};

export { app, analytics }; 