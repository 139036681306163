import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../theme';
import colors from '../theme/colors';
import SEO from '../components/SEO';

// Main container styles
const ContactPageWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: ${colors.background};
`;

const ContactContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 1rem;
  box-sizing: border-box;
`;

// Section styles
const ContactSection = styled.section`
  margin-bottom: 5rem;
  width: 100%;
  box-sizing: border-box;
  
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
`;

const ContactTitle = styled.h1`
  font-size: 2.5rem;
  color: ${colors.text};
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  font-family: ${theme.typography.fontFamily.secondary};
  grid-column: 1 / -1;
  
  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const ContactText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  color: ${colors.text};
  grid-column: 1 / -1;
  
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

// Form styles
const FormContainer = styled.div`
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  
  @media (max-width: 767px) {
    margin-bottom: 2rem;
  }
`;

const FormTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.text};
  margin-bottom: 1.5rem;
  font-family: ${theme.typography.fontFamily.secondary};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: ${colors.text};
  font-weight: 500;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: ${colors.text};
    box-shadow: 0 0 0 2px rgba(12, 66, 62, 0.1);
  }
`;

const TextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: ${colors.text};
    box-shadow: 0 0 0 2px rgba(12, 66, 62, 0.1);
  }
`;

const SubmitButton = styled.button`
  background-color: ${colors.active};
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
  
  &:hover {
    background-color: ${colors.text};
  }
  
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled.div`
  background-color: #e6f7e6;
  color: #2e7d32;
  padding: 1rem;
  border-radius: 6px;
  margin-top: 1rem;
  display: ${props => props.visible ? 'block' : 'none'};
`;

// Contact info styles
const ContactInfoContainer = styled.div`
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
`;

const ContactInfoTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.text};
  margin-bottom: 1.5rem;
  font-family: ${theme.typography.fontFamily.secondary};
`;

const ContactInfoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ContactInfoItem = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ContactInfoIcon = styled.div`
  margin-right: 1rem;
  color: ${colors.text};
`;

const ContactInfoText = styled.div`
  flex: 1;
`;

const ContactInfoLabel = styled.h3`
  font-size: 1.1rem;
  color: ${colors.text};
  margin-bottom: 0.25rem;
  font-family: ${theme.typography.fontFamily.secondary};
`;

const ContactInfoValue = styled.p`
  font-size: 1rem;
  color: ${colors.text};
  line-height: 1.5;
`;

// SVG Icons
const EmailIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" stroke={colors.text} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22 6L12 13L2 6" stroke={colors.text} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const LocationIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 5.02944 7.02944 1 12 1C16.9706 1 21 5.02944 21 10Z" stroke={colors.text} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke={colors.text} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

// Main component
const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // Using Formspree to handle form submission
      const response = await fetch('https://formspree.io/f/mnnjevqn', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      
      if (response.ok) {
        console.log('Form submitted successfully');
        setIsSubmitted(true);
        
        // Reset form after submission
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
        
        // Hide success message after 5 seconds
        setTimeout(() => {
          setIsSubmitted(false);
        }, 5000);
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <>
      <SEO 
        title="Contact Us"
        description="Get in touch with the Moveflow team for questions about our location intelligence solutions, pricing, or custom implementations. We're here to help with your data needs."
        keywords="contact, location intelligence, support, business inquiries, data solutions, custom implementation"
        path="/contact"
      />
      <ContactPageWrapper>
        <Header />
        <ContactContainer>
          <ContactTitle>Contact Us</ContactTitle>
          <ContactText>
            Have questions about our products or services? We'd love to hear from you. Fill out the form below or reach out to us directly using the contact information provided.
          </ContactText>
          
          <ContactSection>
            <FormContainer>
              <FormTitle>Send Us a Message</FormTitle>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label htmlFor="name">Name</Label>
                  <Input 
                    type="text" 
                    id="name" 
                    name="name" 
                    value={formData.name} 
                    onChange={handleChange} 
                    required 
                  />
                </FormGroup>
                
                <FormGroup>
                  <Label htmlFor="email">Email</Label>
                  <Input 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                    required 
                  />
                </FormGroup>
                
                <FormGroup>
                  <Label htmlFor="subject">Subject</Label>
                  <Input 
                    type="text" 
                    id="subject" 
                    name="subject" 
                    value={formData.subject} 
                    onChange={handleChange} 
                    required 
                  />
                </FormGroup>
                
                <FormGroup>
                  <Label htmlFor="message">Message</Label>
                  <TextArea 
                    id="message" 
                    name="message" 
                    value={formData.message} 
                    onChange={handleChange} 
                    required 
                  />
                </FormGroup>
                
                <SubmitButton type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Sending...' : 'Send Message'}
                </SubmitButton>
                
                <SuccessMessage visible={isSubmitted}>
                  Thank you for your message! We'll get back to you as soon as possible.
                </SuccessMessage>
              </Form>
            </FormContainer>
            
            <ContactInfoContainer>
              <ContactInfoTitle>Contact Information</ContactInfoTitle>
              <ContactInfoList>
                <ContactInfoItem>
                  <ContactInfoIcon>
                    <EmailIcon />
                  </ContactInfoIcon>
                  <ContactInfoText>
                    <ContactInfoLabel>Email</ContactInfoLabel>
                    <ContactInfoValue>info@moveflow.ai</ContactInfoValue>
                  </ContactInfoText>
                </ContactInfoItem>
                
                <ContactInfoItem>
                  <ContactInfoIcon>
                    <LocationIcon />
                  </ContactInfoIcon>
                  <ContactInfoText>
                    <ContactInfoLabel>Location</ContactInfoLabel>
                    <ContactInfoValue>Charleston, South Carolina</ContactInfoValue>
                  </ContactInfoText>
                </ContactInfoItem>
              </ContactInfoList>
            </ContactInfoContainer>
          </ContactSection>
        </ContactContainer>
        <Footer />
      </ContactPageWrapper>
    </>
  );
};

export default Contact; 