import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import theme from '../theme';

// Animation for the spinner
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Animation for the pulse effect
const pulse = keyframes`
  0% { opacity: 0.6; transform: scale(0.98); }
  50% { opacity: 1; transform: scale(1); }
  100% { opacity: 0.6; transform: scale(0.98); }
`;

// Simple spinner container for full-page loading
const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${props => props.fullPage || props.fullScreen ? '100vh' : 'auto'};
  width: 100%;
  position: ${props => props.fullScreen ? 'fixed' : 'relative'};
  top: 0;
  left: 0;
  z-index: ${props => props.fullScreen ? '9999' : '1'};
  background-color: ${props => props.fullScreen ? theme.colors.background : 'transparent'};
`;

// Basic spinner element
const Spinner = styled.div`
  width: ${props => props.size || '40px'};
  height: ${props => props.size || '40px'};
  border: ${props => props.borderWidth || '4px'} solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: ${props => props.color || theme.colors.primary};
  animation: ${spin} 1s linear infinite;
`;

// Container for the detailed loading indicator with message and progress
const LoadingContainer = styled.div`
  position: ${props => props.fullScreen ? 'fixed' : 'absolute'};
  top: ${props => props.fullScreen ? '0' : '50%'};
  left: ${props => props.fullScreen ? '0' : '40%'};
  width: ${props => props.fullScreen ? '100%' : '20%'};
  height: ${props => props.fullScreen ? '100%' : 'auto'};
  min-width: ${props => props.fullScreen ? '100%' : '20%'};
  transform: ${props => props.fullScreen ? 'none' : 'translate(-50%, -50%)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: ${props => props.fullScreen ? theme.colors.background : 'rgba(255, 255, 255, 0.95)'};
  padding: ${props => props.fullScreen ? '0' : '20px'};
  border-radius: ${props => props.fullScreen ? '0' : '12px'};
  box-shadow: ${props => props.fullScreen ? 'none' : '0 4px 12px rgba(0, 0, 0, 0.15)'};
  backdrop-filter: ${props => props.fullScreen ? 'none' : 'blur(4px)'};
  animation: ${props => props.fullScreen ? 'none' : css`${pulse} 2s infinite ease-in-out`};
  text-align: center;
  margin: 0;
  pointer-events: auto;
  
  @media (max-width: 768px) {
    width: ${props => props.fullScreen ? '100%' : '40%'};
    min-width: ${props => props.fullScreen ? '100%' : '40%'};
    left: ${props => props.fullScreen ? '0' : '30%'};
    padding: ${props => props.fullScreen ? '0' : '15px'};
    border-radius: ${props => props.fullScreen ? '0' : '10px'};
  }
`;

// Loading text
const LoadingText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.text};
  text-align: center;
  margin-top: ${props => props.noMargin ? '0' : '15px'};
  
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

// Progress bar container
const ProgressContainer = styled.div`
  width: 100%;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-top: 10px;
  overflow: hidden;
`;

// Progress bar fill
const ProgressFill = styled.div`
  height: 100%;
  width: ${props => props.progress || 0}%;
  background: linear-gradient(90deg, ${theme.colors.primary}, ${theme.colors.secondary});
  border-radius: 3px;
  transition: width 0.3s ease;
`;

/**
 * Versatile loading component that can be used in different contexts:
 * - Simple spinner (default)
 * - Full page loading spinner
 * - Loading indicator with message
 * - Loading indicator with progress bar
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isVisible - Whether the loading component is visible (defaults to true)
 * @param {string} props.message - Loading message to display
 * @param {number} props.progress - Progress percentage (0-100)
 * @param {boolean} props.showProgress - Whether to show the progress bar
 * @param {boolean} props.fullPage - Whether to display as a full page spinner
 * @param {boolean} props.fullScreen - Whether to display as a full screen overlay
 * @param {string} props.size - Size of the spinner (e.g., '40px')
 * @param {string} props.color - Color of the spinner
 * @param {string} props.borderWidth - Border width of the spinner
 */
const LoadingComponent = ({ 
  isVisible = true, 
  message = '', 
  progress = 0,
  showProgress = false,
  fullPage = false,
  fullScreen = false,
  size,
  color,
  borderWidth
}) => {
  if (!isVisible) return null;
  
  // Simple spinner (used in Home.js and NotFound.js)
  if (fullPage && !message) {
    return (
      <SpinnerContainer fullPage>
        <Spinner size={size} color={color} borderWidth={borderWidth} />
      </SpinnerContainer>
    );
  }
  
  // Full screen loading (used in App.js)
  if (fullScreen) {
    return (
      <SpinnerContainer fullScreen>
        <Spinner size={size} color={color} borderWidth={borderWidth} />
        {message && <LoadingText>{message}</LoadingText>}
      </SpinnerContainer>
    );
  }
  
  // Detailed loading indicator with message and optional progress (used in Demo.js)
  return (
    <LoadingContainer>
      <Spinner size={size} color={color} borderWidth={borderWidth} />
      {message && <LoadingText>{message}</LoadingText>}
      {showProgress && (
        <ProgressContainer>
          <ProgressFill progress={progress} />
        </ProgressContainer>
      )}
    </LoadingContainer>
  );
};

export default LoadingComponent; 